import React from 'react';
import { Form } from 'semantic-ui-react';

const PreferredLanguageSection = ({ language, setLanguage }) => {
  return (
    <Form.Group inline>
      <label>Preferred Language</label>
      <Form.Radio
        label="English"
        value="English"
        checked={language === 'English'}
        onChange={() => setLanguage('English')}
      />
      <Form.Radio
        label="Spanish"
        value="Spanish"
        checked={language === 'Spanish'}
        onChange={() => setLanguage('Spanish')}
      />
      <Form.Radio
        label="Chinese - Traditional"
        value="ChineseTraditional"
        checked={language === 'ChineseTraditional'}
        onChange={() => setLanguage('ChineseTraditional')}
      />
      <Form.Radio
        label="Chinese - Simplified"
        value="ChineseSimplified"
        checked={language === 'ChineseSimplified'}
        onChange={() => setLanguage('ChineseSimplified')}
      />
      <Form.Radio
        label="Korean"
        value="Korean"
        checked={language === 'Korean'}
        onChange={() => setLanguage('Korean')}
      />
    </Form.Group>
  );
};

export default PreferredLanguageSection;
