import React from 'react';
import { Form } from 'semantic-ui-react';
import {
  formatDate,
  removeSpecialChars,
  toTitleCase,
} from '../../Common/utils';
import { memberTitles } from '../../Common/Values';

const NameAndDobSection = ({
  memberTitle,
  memberName,
  dob,
  setMemberTitle,
  setMemberName,
  setDOB,
}) => {
  const handleNameChange = (event) => {
    const { name, value } = event.target;
    setMemberName({
      ...memberName,
      [name]: removeSpecialChars(toTitleCase(value)).trim(),
    });
  };

  const handleDOBChange = (event) => {
    setDOB(formatDate(dob, event.target.value));
  };

  return (
    <Form.Group widths="equal">
      <Form.Dropdown
        fluid
        label="Title"
        selection
        value={memberTitle}
        options={memberTitles}
        onChange={(e, { value }) => setMemberTitle(value)}
        style={{ width: '75px' }}
      />
      <Form.Input
        required
        name="firstName"
        label="First name"
        placeholder="First name"
        value={memberName.firstName}
        onChange={handleNameChange}
      />
      <Form.Input
        required
        name="lastName"
        label="Last name"
        placeholder="Last name"
        value={memberName.lastName}
        onChange={handleNameChange}
      />
      <Form.Input
        required
        name="dob"
        label="Date of Birth"
        placeholder="MM/DD/YYYY"
        value={dob}
        onChange={handleDOBChange}
      />
    </Form.Group>
  );
};

export default NameAndDobSection;
