import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Modal,
} from 'semantic-ui-react';
import 'moment-timezone';
import { useStitchMembers } from '../../StitchMembers';
import ReactQuill from 'react-quill';
import * as Emoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { useStitchAuth } from '../../StitchAuth';

const Small = styled.div`
  font-size: 10px;
`;

const Note = styled.span`
  font-size: 12px;
`;

const Italic = styled.span`
  font-style: italic;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const TemplateKey = styled.div`
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const SmallPadding = styled.div`
  padding-top: 2px;
`;

const { Quill } = ReactQuill;
Quill.register('modules/emoji', Emoji);
const toolbarOptions = {
  container: [['emoji']],
  handlers: {
    emoji: function () {},
  },
};
const quillModules = {
  toolbar: toolbarOptions,
  'emoji-toolbar': true,
  'emoji-shortname': true,
};

const nameString = (members) => {
  let result = '';
  members.forEach((member, i) => {
    result += member.name;
    if (i < members.length - 1) {
      result += ', ';
    }
  });
  return result;
};

export default function GroupTextModal({ members, isOpen, onClose }) {
  const [quill, setQuill] = useState();
  const [contentRaw, setContentRaw] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageSentSuccess, setMessageSentSuccess] = useState(false);
  const { actions } = useStitchMembers();
  const { userCustomData } = useStitchAuth();

  const content = contentRaw.replace(/<[^>]+>/g, '');
  const membersToSend = members.filter((member) => member.optInSMS);
  const membersNotSend = members.filter((member) => !member.optInSMS);

  const fullClose = () => {
    setContentRaw('');
    setMessageSentSuccess(false);
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    await Promise.all(
      membersToSend.map((member) => {
        let titleName = '';
        if (member.memberTitle) {
          titleName = member.memberTitle;
        } else {
          titleName = member.gender === 'male' ? 'Mr.' : 'Ms.';
        }
        const firstName = titleName + ' ' + member.name.split(' ')[0];

        return actions.updateMessage(
          member,
          content.replace(/\$NAME/g, firstName),
        );
      }),
    );
    let range = quill.getEditor().getSelection();
    let position = range ? range.index : 0;
    quill.getEditor().deleteText(0, position);
    setMessageSentSuccess(true);
    setLoading(false);
  };

  let modalGuts = (
    <>
      <Bold>Write text message:</Bold>
      <TemplateKey>
        <Italic>Template Information:</Italic> <br />
        $NAME: First name. ex: for Jane Doe, $NAME becomes 'Jane'
      </TemplateKey>
      <div
        style={{
          paddingTop: '4px',
          paddingBottom: '6px',
        }}
      >
        <ReactQuill
          theme="snow"
          ref={(el) => setQuill(el)}
          onChange={(newContents) => setContentRaw(newContents)}
          placeholder="write something here"
          style={{ backgroundColor: '#fff' }}
          modules={quillModules}
        />
      </div>
      <Bold>Message that will be sent</Bold>, to example member, Jane
      Doe:{' '}
      <Note>($NAME will change appropriately for each member.)</Note>
      <br />
      <SmallPadding />
      {content.replace(/\$NAME/g, 'Ms. Jane')}
      <br />
      {membersToSend.length > 0 && (
        <>
          <br />
          <Bold>
            {membersToSend.length === 1
              ? 'This selected member'
              : `These ${membersToSend.length} selected members`}{' '}
            will receive the above text message:
          </Bold>
          <br />
          <Small>{nameString(membersToSend)}</Small>
        </>
      )}
      {membersNotSend.length > 0 && (
        <>
          <br />
          <Bold>
            {membersNotSend.length === 1
              ? 'This selected member is'
              : `These ${membersNotSend.length} selected members are`}{' '}
            unsubscribed from texts and will NOT receive the text
            message:
          </Bold>
          <br />
          <Small>{nameString(membersNotSend)}</Small>
        </>
      )}
    </>
  );
  if (!userCustomData.text_usage_consent) {
    modalGuts = (
      <>
        SMS messaging is disabled for your organization. Enable this
        feature <a href="/settings/sms-group">here</a>.
      </>
    );
  }
  if (messageSentSuccess) {
    modalGuts = <>Text message sent successfully!</>;
  }

  return (
    <>
      <Modal onClose={fullClose} open={isOpen} size="fullscreen">
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        <Modal.Header>Send Group Text</Modal.Header>
        <Modal.Content scrolling>{modalGuts}</Modal.Content>
        <Modal.Actions>
          {messageSentSuccess ||
          !userCustomData.text_usage_consent ? (
            <>
              <Button
                color="red"
                onClick={() => {
                  fullClose();
                }}
              >
                <Icon name="ban" /> Close
              </Button>
            </>
          ) : (
            <>
              <Button
                color="red"
                onClick={() => {
                  fullClose();
                }}
              >
                <Icon name="x" /> Cancel
              </Button>
              <Button
                disabled={membersToSend.length === 0 || !content}
                color="green"
                onClick={async () => {
                  handleSubmit();
                }}
              >
                <Icon name="paper plane" /> Send Text
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}
