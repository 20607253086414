import React from 'react';
import { Table, Icon, Checkbox, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  padding-left: 4px;
  padding-top: 6px;
`;

export default function TableHeader({
  sortParam,
  setSortParam,
  columns,
  isCheckboxChecked,
  checkCheckbox,
}) {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <CheckboxWrapper>
            <Checkbox
              checked={isCheckboxChecked}
              onChange={() => {
                checkCheckbox();
              }}
            />
          </CheckboxWrapper>
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => {
            if (sortParam === 'nameAsc') {
              setSortParam('nameDesc');
            } else {
              setSortParam('nameAsc');
            }
          }}
        >
          Name&nbsp;
          {!['nameAsc', 'nameDesc'].includes(sortParam) && (
            <Icon name="sort" className="grey text" />
          )}
          {sortParam === 'nameAsc' && <Icon name="sort up" />}
          {sortParam === 'nameDesc' && <Icon name="sort down" />}
        </Table.HeaderCell>
        <Table.HeaderCell
          textAlign="center"
          onClick={() => {
            if (sortParam === 'activityAsc') {
              setSortParam('activityDesc');
            } else {
              setSortParam('activityAsc');
            }
          }}
        >
          Freq&nbsp;
          {!['activityAsc', 'activityDesc'].includes(sortParam) && (
            <Icon name="sort" className="grey text" />
          )}
          {sortParam === 'activityAsc' && <Icon name="sort up" />}
          {sortParam === 'activityDesc' && <Icon name="sort down" />}
        </Table.HeaderCell>
        {columns.bodyWeight && (
          <Table.HeaderCell textAlign="center">
            Wt<span style={{ fontSize: '0.7em' }}> (lbs)</span>
          </Table.HeaderCell>
        )}
        {columns.systolicBP && (
          <Table.HeaderCell textAlign="center">
            BP<span style={{ fontSize: '0.7em' }}> (mmHg)</span>
          </Table.HeaderCell>
        )}
        {columns.pulse && (
          <Table.HeaderCell textAlign="center">
            HR <span style={{ fontSize: '0.7em' }}>(bpm)</span>
          </Table.HeaderCell>
        )}
        {columns.ecg && (
          <Table.HeaderCell textAlign="center">ECG</Table.HeaderCell>
        )}
        {columns.bloodSugar && (
          <Table.HeaderCell textAlign="center">
            GLC
            <span style={{ fontSize: '0.7em' }}> (mg/dL)</span>
          </Table.HeaderCell>
        )}
        {columns.step && (
          <Table.HeaderCell textAlign="center">
            Steps
          </Table.HeaderCell>
        )}
        {columns.spo2 && (
          <Table.HeaderCell textAlign="center">
            SpO2
            <span style={{ fontSize: '0.7em' }}> (%)</span>
          </Table.HeaderCell>
        )}
        {columns.pef && (
          <Table.HeaderCell textAlign="center">
            PEF
            <span style={{ fontSize: '0.7em' }}> (l/min)</span>
          </Table.HeaderCell>
        )}
        <Table.HeaderCell textAlign="center" width={3}>
          <Popup
            trigger={<span>To-Do Items</span>}
            position="bottom right"
            content="Please use the side menu on left to filter out to-do items."
          />
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Memos</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}
