import React, { useState, useEffect } from 'react';
import '../Common/Layout.css';
import {
  Grid,
  Header,
  Table,
  Button,
  Dimmer,
  Loader,
  Divider,
} from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
//import { registry, devicereqs } from "../../stitch";
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ObjectID from 'bson-objectid';

function MemberDevicesTable(props) {
  const deviceMap = {
    BT004: 'Weight Scale',
    BT105: 'BP Cuff',
    SMIG: 'Glucometer',
    ACKM: 'Kardia Mobile',
  };

  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Recipient</Table.HeaderCell>
          <Table.HeaderCell>Phone</Table.HeaderCell>
          <Table.HeaderCell>Device</Table.HeaderCell>
          <Table.HeaderCell>IMEI</Table.HeaderCell>
          <Table.HeaderCell>Reference #</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Tracking</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(
          _.orderBy(
            props.data,
            [
              function (currEntry) {
                let currDate = new Date(currEntry.statusUpdateTime);
                return isValidDate(currDate) ? currDate : 0;
              },
            ],
            ['desc'],
          ),
          (currMember, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Link
                  to={`/member-details/${ObjectID(
                    currMember.memberId,
                  ).toHexString()}/profile`}
                >
                  {currMember.name}
                </Link>
              </Table.Cell>
              <Table.Cell>{currMember.phone}</Table.Cell>
              <Table.Cell>
                <Link
                  to={`/member-details/${ObjectID(
                    currMember.memberId,
                  ).toHexString()}/measurements`}
                >
                  {deviceMap[currMember.device]}
                </Link>
              </Table.Cell>
              <Table.Cell>{currMember.imei}</Table.Cell>
              <Table.Cell>
                <span>{currMember.reference}</span>
                <span>{currMember.order_number}</span>
              </Table.Cell>
              <Table.Cell>
                {currMember.status}
                {currMember.status === 'requested' && (
                  <span>
                    ;&nbsp;
                    <Button
                      color="red"
                      size="mini"
                      onClick={() =>
                        props.handleCancelRegistry(currMember._id)
                      }
                    >
                      Cancel
                    </Button>
                  </span>
                )}
                <br />
                <small>
                  {new Date(
                    currMember.statusUpdateTime,
                  ).toLocaleString()}
                </small>
              </Table.Cell>
              <Table.Cell>
                {_.map(currMember.tracking || [], (currTrack, i) => {
                  let url = '';
                  if (currMember.carrierName === 'UPS') {
                    url =
                      'https://www.ups.com/track?loc=null&tracknum=' +
                      currTrack +
                      '&requester=WT/trackdetails';
                  } else if (currMember.carrierName === 'FEDEX') {
                    url =
                      'https://www.fedex.com/apps/fedextrack/?tracknumbers=' +
                      currTrack;
                  }
                  if (currTrack) {
                    return (
                      <a
                        key={currTrack}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {currMember.carrierName + ': ' + currTrack}
                      </a>
                    );
                  } else {
                    return <span key={i}></span>;
                  }
                })}
              </Table.Cell>
            </Table.Row>
          ),
        )}
      </Table.Body>
    </Table>
  );
}

function DeviceOnlyTable(props) {
  const deviceMap = {
    '0bf06350-8bf1-11ea-ac1f-aa0000d39f2e': 'Weight Scale',
    '0c3695e6-8bf1-11ea-9175-aa0000d39f2e': 'BP Cuff',
  };

  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Recipient</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Device</Table.HeaderCell>
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Reference #</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Tracking</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(
          _.orderBy(
            props.data,
            [
              function (currEntry) {
                let currDate = new Date(currEntry.statusUpdateTime);
                return isValidDate(currDate) ? currDate : 0;
              },
            ],
            ['desc'],
          ),
          (currMember, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {currMember.firstName && (
                  <span>
                    {currMember.firstName + ' ' + currMember.lastName}
                  </span>
                )}
                {currMember.customer_name && (
                  <span>{currMember.customer_name}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {currMember.shippingAddress && (
                  <span>
                    {currMember.shippingAddress.street +
                      ', ' +
                      currMember.shippingAddress.city +
                      ', ' +
                      currMember.shippingAddress.state +
                      ' ' +
                      currMember.shippingAddress.postalCode}
                  </span>
                )}
                {currMember.address1 && (
                  <span>
                    {currMember.address1 +
                      ', ' +
                      currMember.city +
                      ', ' +
                      currMember.state +
                      ' ' +
                      currMember.zipcode}
                  </span>
                )}
              </Table.Cell>
              <Table.Cell>
                {currMember.kitId && (
                  <span>{deviceMap[currMember.kitId]}</span>
                )}
                {/* 3 lines = regular device request (glucometer, lancets, test strips) */}
                {currMember.lines && currMember.lines.length > 3 && (
                  <span>Glucometer Kit</span>
                )}
                {/* 2 lines = refill order (lancets, test strips) */}
                {currMember.lines &&
                  currMember.lines.length === 2 && (
                    <span>Glucometer Refill</span>
                  )}
                {/* l line = bulk order Python script made to handle 1 item at a time */}
                {currMember.lines &&
                  currMember.lines.length === 1 && (
                    <span>Glucometer Parts Bulk Order</span>
                  )}
              </Table.Cell>
              <Table.Cell>
                {currMember.quantity && (
                  <span>{currMember.quantity}</span>
                )}
                {currMember.lines && (
                  <span>{currMember.lines[0].quantity}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {currMember.quantity && (
                  <span>{currMember.reference}</span>
                )}
                {currMember.lines && (
                  <span>{currMember.order_number}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {currMember.status}
                <br />
                <small>
                  {new Date(
                    currMember.statusUpdateTime,
                  ).toLocaleString()}
                </small>
              </Table.Cell>
              <Table.Cell>
                {_.map(
                  currMember.tracking || [],
                  (currTracking, i) => {
                    let url = '';
                    if (currMember.carrierName === 'UPS') {
                      url =
                        'https://www.ups.com/track?loc=null&tracknum=' +
                        currTracking +
                        '&requester=WT/trackdetails';
                    } else if (currMember.carrierName === 'FEDEX') {
                      url =
                        'https://www.fedex.com/apps/fedextrack/?tracknumbers=' +
                        currTracking;
                    }
                    if (currTracking) {
                      return (
                        <a
                          key={currTracking}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {currMember.carrierName +
                            ': ' +
                            currTracking}
                        </a>
                      );
                    } else {
                      return <span key={i}></span>;
                    }
                  },
                )}
              </Table.Cell>
            </Table.Row>
          ),
        )}
      </Table.Body>
    </Table>
  );
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

export default function TrackDeliveries(props) {
  const {
    userCustomData,
    db: { registry, devicereqs },
  } = useStitchAuth();
  //const [ memberDevicesD, setMemberDevicesD ] = useState([]);
  //const [ deviceOnlysD, setDeviceOnlysD ] = useState([]);
  const [memberDevicesP, setMemberDevicesP] = useState([]);
  const [deviceOnlysP, setDeviceOnlysP] = useState([]);
  const [loading, setLoading] = useState(true);

  const isDelivered = (track) => {
    if (track && track.toLowerCase() === 'delivered') {
      return true;
    } else {
      return false;
    }
  };

  const loadData = async (mounted) => {
    let query = {
      device: { $in: ['BT004', 'BT105', 'SMIG', 'ACKM'] },
    };
    if (!userCustomData.superUserAccess) {
      query['owner_id'] = userCustomData.group_id;
    }
    registry.find(query).then((res) => {
      if (mounted) {
        //setMemberDevicesD(res.filter(x => isDelivered(x.status) && x.name !== ""));
        setMemberDevicesP(
          res.filter(
            (track) =>
              !isDelivered(track.status) && track.name !== '',
          ),
        );
      }
    });
    query = {};
    if (!userCustomData.superUserAccess) {
      query['owner_id'] = userCustomData.group_id;
    }
    devicereqs.find(query).then((res) => {
      if (mounted) {
        //setDeviceOnlysD(res.filter(x => isDelivered(x.status)));
        setDeviceOnlysP(
          res.filter((track) => !isDelivered(track.status)),
        );
      }
    });
  };

  useEffect(() => {
    let mounted = true;
    if (userCustomData) {
      let query = {
        device: { $in: ['BT004', 'BT105', 'SMIG', 'ACKM'] },
      };
      if (!userCustomData.superUserAccess) {
        query['owner_id'] = userCustomData.group_id;
      }
      registry.find(query).then((res) => {
        if (mounted) {
          //setMemberDevicesD(res.filter(x => isDelivered(x.status) && x.name !== ""));
          setMemberDevicesP(
            res.filter(
              (track) =>
                !isDelivered(track.status) && track.name !== '',
            ),
          );
        }
      });
      query = {};
      if (!userCustomData.superUserAccess) {
        query['owner_id'] = userCustomData.group_id;
      }
      devicereqs.find(query).then((res) => {
        if (mounted) {
          //setDeviceOnlysD(res.filter(x => isDelivered(x.status)));
          setDeviceOnlysP(
            res.filter((track) => !isDelivered(track.status)),
          );
        }
      });
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [userCustomData, devicereqs, registry]);

  const handleCancelRegistry = async (_id) => {
    setLoading(true);
    await registry.deleteOne({ _id: _id });
    await loadData(true);
    setLoading(false);
  };

  return (
    <div className="messages box">
      {loading && (
        <div className="messages box">
          <Dimmer active inverted>
            <Loader inverted>
              Visualizing Care Calendar events...
            </Loader>
          </Dimmer>
        </div>
      )}
      <Grid style={{ margin: 0 }}>
        <Grid.Row>
          <Grid.Column>
            <small className="grey text">
              NOTES: <br />
              1. Already delivered products are not shown in this
              page.
              <br />
              2. To see the devices that are already delivered, please
              use the{' '}
              <Link to="/reports/device/latest">
                Device Management
              </Link>{' '}
              page.
              <br />
              3. If you have any additional questions regarding the
              package delivery, please refer to the Reference # on the
              table when sending an inquiry.
            </small>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as="h3">
              Device-Only Requests
              <Header.Subheader>
                # currently active orders: {deviceOnlysP.length}
              </Header.Subheader>
            </Header>
            <DeviceOnlyTable data={deviceOnlysP} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as="h3">
              Member-assigned Device Requests
              <Header.Subheader>
                # currently active orders: {memberDevicesP.length}
              </Header.Subheader>
            </Header>
            <MemberDevicesTable
              data={memberDevicesP}
              handleCancelRegistry={handleCancelRegistry}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
