import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

const DeviceAssignmentTopSection = ({ devices, stats }) => {
  return (
    <>
      {devices.length > 0 && (
        <Grid.Row columns={stats.length + 3}>
          {stats.map((stat, i) => (
            <Grid.Column textAlign="center" key={i}>
              <Header as="h2">
                {stat[1]}
                <Header.Subheader>{stat[0]}</Header.Subheader>
              </Header>
            </Grid.Column>
          ))}
        </Grid.Row>
      )}
      <Grid.Row columns={1}>
        <Grid.Column>
          <small className="grey text">
            NOTES: 1. A member is allowed to own multiple devices,
            however, please keep at most one device type per member.{' '}
            <br />
            2. If you want to remove a member from the selected
            device, please type and assign "no owner" on the Current
            User column.
            <br />
            3. Glucometers do not show Signal Strength and Battery
            Voltage. Glucometer batteries are re-chargeable.
            <br />
            4. Please contact the support, if you need to change the
            settings of the other devices.
          </small>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default DeviceAssignmentTopSection;
