import React from 'react';
import PropTypes from 'prop-types';

// Create a React Context that lets us expose and access filter params for member list
// without passing props through many levels of the component tree
const GeneralParamsContext = React.createContext();

// Create a React Hook that lets us get data from our auth context
export function useGeneralParams() {
  const context = React.useContext(GeneralParamsContext);
  if (!context) {
    throw new Error(
      `useGeneralParams must be used within a generalParamsProvider`,
    );
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function GeneralParamsProvider(props) {
  // list filters on the member list
  const [showParam, setShowParam] = React.useState('all');
  const [sortParam, setSortParam] = React.useState('measureDateDesc');
  const [listFilter, setListFilter] = React.useState('all-members');
  const [pageNum, setPageNum] = React.useState(0);

  // Twilio Call and Modal Parameters
  const [callModalOpen, setCallModalOpen] = React.useState(false);
  const [ongoingCall, setOngoingCall] = React.useState(false);
  const [isCallingMember, setIsCallingMember] = React.useState(false); // as opposed to calling prospect

  // We useMemo to improve performance by eliminating some re-renders
  const value = React.useMemo(
    () => ({
      // list filters on the member list
      showParam,
      sortParam,
      listFilter,
      pageNum,
      setShowParam,
      setSortParam,
      setListFilter,
      setPageNum,

      // Twilio Call and Modal Parameters
      callModalOpen,
      ongoingCall,
      isCallingMember,
      setCallModalOpen,
      setOngoingCall,
      setIsCallingMember,
    }),
    [
      showParam,
      sortParam,
      listFilter,
      pageNum,
      callModalOpen,
      ongoingCall,
      isCallingMember,
    ],
  );
  return (
    <GeneralParamsContext.Provider value={value}>
      {props.children}
    </GeneralParamsContext.Provider>
  );
}
GeneralParamsProvider.propTypes = {
  children: PropTypes.element,
};
