import React from 'react';
import { Form } from 'semantic-ui-react';
import { platformServices } from '../../../Common/Values';
import { useStitchAuth } from '../../../StitchAuth';
import CcmMemberInfoSection from './CcmMemberInfoSection';

const CcmServiceSection = ({
  ccmServiceFields,
  setCcmServiceFields,
  ccmButtonClicked,
  setCcmButtonClicked,
}) => {
  const { userCustomData } = useStitchAuth();
  const groupServices = userCustomData?.services || [];

  const handleAddCcmServiceButton = () => {
    setCcmButtonClicked(!ccmButtonClicked);
    if (ccmButtonClicked === false) {
      setCcmServiceFields({
        caregiver: { name: '', phone: '' },
        methodOfCommunication: 'phone',
        personToContact: 'patient',
        ccmPatientConsent: false,
      });
    }
  };

  const AddCcmServiceButton = (
    <Form.Group>
      <Form.Button
        size="tiny"
        icon={ccmButtonClicked ? 'remove' : 'add'}
        content={
          ccmButtonClicked ? 'Remove CCM Service' : 'CCM Service'
        }
        onClick={handleAddCcmServiceButton}
        positive={!ccmButtonClicked}
        negative={ccmButtonClicked}
        label="Add Additional Services"
      />
    </Form.Group>
  );

  return (
    <>
      {groupServices.includes(platformServices.CCM) && (
        <>
          {AddCcmServiceButton}
          {ccmButtonClicked && (
            <CcmMemberInfoSection
              ccmServiceFields={ccmServiceFields}
              setCcmServiceFields={setCcmServiceFields}
            />
          )}
        </>
      )}
    </>
  );
};

export default CcmServiceSection;
