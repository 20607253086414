import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Loader,
  Grid,
  Checkbox,
} from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import { useStitchMembers } from '../StitchMembers';
import '../Common/Layout.css';
import ReactQuill from 'react-quill';
import * as Emoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';

const { Quill } = ReactQuill;
Quill.register('modules/emoji', Emoji);

function MessageInput(props) {
  const [loading, setLoading] = useState(false);
  const { userCustomData } = useStitchAuth();
  const { prospectOne, actions } = useStitchMembers();
  const [quill, setQuill] = useState();
  const toolbarOptions = {
    container: [['emoji']],
    handlers: {
      emoji: function () {},
    },
  };
  const quillModules = {
    toolbar: toolbarOptions,
    'emoji-toolbar': true,
    'emoji-shortname': true,
  };

  const handleSubmit = async () => {
    setLoading(true);
    const contentRaw = quill.getEditor().root.innerHTML;
    const content = contentRaw.replace(/<[^>]+>/g, '');
    await actions.updateMessage(
      prospectOne,
      content,
      undefined,
      'prospects',
    );
    let range = quill.getEditor().getSelection();
    let position = range ? range.index : 0;
    quill.getEditor().deleteText(0, position);
    setLoading(false);
  };

  return (
    <Grid>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      <Grid.Row columns={2}>
        <Grid.Column width={12}>
          <div style={{ height: '120px', overflowY: 'auto' }}>
            <ReactQuill
              theme="snow"
              ref={(el) => setQuill(el)}
              placeholder="write something here"
              style={{ backgroundColor: '#fff' }}
              modules={quillModules}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid style={{ paddingRight: '2em' }}>
            <Grid.Row>
              <Grid.Column>
                <small className="grey text thin">
                  A text message will be sent from this RPM platform's
                  number: +1-480-725-1429.
                  <br />
                  {prospectOne && !prospectOne.optInSMS && (
                    <span style={{ color: '#dc3545' }}>
                      The member opted out of receiving text messages.
                      You cannot send a text message from this number
                      till the member replies "START" to this number.
                    </span>
                  )}
                  {prospectOne && !userCustomData.text_usage_consent && (
                    <span style={{ color: '#dc3545' }}>
                      To use this feature, please enable our text
                      messaging capabilities at{' '}
                      <a href="/settings/sms-group">#sms-group</a>
                    </span>
                  )}
                </small>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {prospectOne && userCustomData && (
                  <Button
                    color="yellow"
                    floated="right"
                    onClick={handleSubmit}
                    fluid
                    disabled={
                      !userCustomData.text_usage_consent ||
                      !prospectOne.optInSMS
                    }
                  >
                    Send Message
                    {!prospectOne.optInSMS && (
                      <small className="red text">
                        {' '}
                        WARNING: The member opted out of receiving
                        text messages.
                      </small>
                    )}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function NoteInput(props) {
  const [loading, setLoading] = useState(false);
  const [isMemo, setIsMemo] = useState(false);
  const { prospectOne, actions } = useStitchMembers();
  const [quill, setQuill] = useState();
  const toolbarOptions = {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['image', 'code-block'],
      ['emoji'],
    ],
    handlers: {
      emoji: function () {},
    },
  };
  const quillModules = {
    toolbar: toolbarOptions,
    'emoji-toolbar': true,
    'emoji-shortname': true,
  };

  const handleSubmit = async () => {
    setLoading(true);
    const contentRaw = quill.getEditor().root.innerHTML;
    const content = contentRaw.replace(/<\/?span[^>]*>/g, '');
    await actions.updateNote(
      prospectOne,
      content,
      new Date().toISOString(),
      isMemo,
      'prospects',
    );
    let range = quill.getEditor().getSelection();
    let position = range ? range.index : 0;
    quill.getEditor().deleteText(0, position);
    setLoading(false);
  };

  return (
    <Grid>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      <Grid.Row columns={2}>
        <Grid.Column width={12}>
          <div style={{ height: '120px', overflowY: 'auto' }}>
            <ReactQuill
              theme="snow"
              ref={(el) => setQuill(el)}
              placeholder="write something here"
              style={{ backgroundColor: '#fff' }}
              modules={quillModules}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid style={{ paddingRight: '2em' }}>
            <Grid.Row>
              <Grid.Column>
                {/* TODO: Checkbox needs to clear after note has been submitted */}
                <Checkbox
                  size="mini"
                  label={
                    <label>
                      <small className="grey text thin">
                        This is a memo.
                      </small>
                    </label>
                  }
                  checked={isMemo}
                  onClick={() => setIsMemo(!isMemo)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  style={{
                    backgroundColor: '#1b2a4e',
                    color: '#fff',
                  }}
                  fluid
                  onClick={handleSubmit}
                >
                  Write Notes
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default function InputSection(props) {
  // NOTE: write box height, set as 300 px. quill
  if (props.pathname === 'text-messages') {
    return (
      <div className="write box">
        <MessageInput />
      </div>
    );
  } else {
    return (
      <div className="write box">
        <NoteInput />
      </div>
    );
  }
}
