import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';

const CareTeamSection = ({
  assignedPhysician,
  assignedCareManager,
  practiceId,
  setAssignedPhysician,
  setAssignedCareManger,
  setPracticeId,
  setLoading,
}) => {
  const {
    userCustomData,
    clientName,
    actions: { getOtherUsers },
    db: { members },
  } = useStitchAuth();
  const [physicianList, setPhysicianList] = useState([]);
  const [practiceList, setPracticeList] = useState([]);

  useEffect(() => {
    if (userCustomData) {
      const practices = userCustomData.practiceList || [];
      setPracticeList(
        practices.sort((a, b) => a.text.localeCompare(b.text)),
      );
      getOtherUsers(userCustomData.group_id).then((user) =>
        setPhysicianList(
          user
            .map((physician) => {
              return {
                text: physician.name + ' (' + physician.email + ')',
                value: physician.id,
                key: physician.id,
              };
            })
            .sort((a, b) => a.text.localeCompare(b.text)),
        ),
      );
      setAssignedPhysician(userCustomData.id);
      setAssignedCareManger(userCustomData.id);
      if (practices.length > 0) {
        setPracticeId(practices[0].value);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, members]);

  return (
    <Form.Group widths="equal">
      <Form.Dropdown
        fluid
        label="Physician"
        selection
        value={assignedPhysician}
        options={physicianList}
        onChange={(e, { value }) => setAssignedPhysician(value)}
      />
      <Form.Dropdown
        fluid
        label="Care Manager"
        selection
        value={assignedCareManager}
        options={physicianList}
        onChange={(e, { value }) => setAssignedCareManger(value)}
      />
      {practiceList.length > 0 && (
        <Form.Dropdown
          fluid
          label={
            clientName === 'iris' ? 'Client Name' : 'Pratice Name'
          }
          selection
          value={practiceId}
          options={practiceList}
          onChange={(e, { value }) => setPracticeId(value)}
        />
      )}
    </Form.Group>
  );
};

export default CareTeamSection;
