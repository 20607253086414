import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
const Bold = styled.span`
  font-weight: 600;
`;

export default function DeleteConfirmationModal({
  open,
  onClose,
  event,
  deleteEvent,
  setLoading,
}) {
  return (
    <Modal onClose={onClose} open={open} size="tiny">
      <Modal.Header>Confirm Delete</Modal.Header>
      <Modal.Content>
        Are you sure you want to delete <Bold>{event.name}</Bold>? It
        cannot be undone.
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          <Icon name="x" /> Cancel
        </Button>
        <Button
          negative
          onClick={async () => {
            setLoading(true);
            await deleteEvent();
            onClose();
            setLoading(false);
          }}
        >
          <Icon name="trash" /> Confirm Deletion
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
