import ObjectID from 'bson-objectid';
import { toDomesticPhoneFormat } from '../../Common/utils';

export const changeOwnership = async (
  _id,
  memberList,
  ownerMap,
  setOwnerMap,
  setLoading,
  registry,
) => {
  setLoading(true);
  const newOwner = memberList.find(
    (x) => x._id === ownerMap[_id].memberId,
  );
  const oldInfo = await registry.findOne({
    imei: ownerMap[_id].imei,
  });

  await registry.updateOne(
    { imei: ownerMap[_id].imei },
    {
      $set: {
        phone: newOwner.phone,
        name: newOwner.name,
        email: newOwner.email,
        memberId: newOwner._id === '' ? '' : ObjectID(newOwner._id),
      },
      $push: {
        exOwners: {
          name: oldInfo.name,
          phone: oldInfo.phone,
          email: oldInfo.email,
          memberId:
            oldInfo.memberId === '' ? '' : ObjectID(oldInfo.memberId),
          time: new Date().toISOString(),
        },
      },
    },
  );
  setOwnerMap({
    ...ownerMap,
    [_id]: {
      ...ownerMap[_id],
      name: newOwner.name,
      phone: newOwner.phone,
      email: newOwner.email,
      memberId: newOwner._id,
      text: newOwner.text,
      clicked: false,
      changed: false,
    },
  });
  setLoading(false);
};

export const getMembers = async (
  superUserAccess,
  members,
  userCustomData,
  isMounted,
  setMemberList,
) => {
  let res = [];
  // Aug 16, 2021, Yubin
  // Devices > # device-assignment was not loading; time-out (for Renal Consultants)
  // Realized the members collection lacked a compound index that is given below
  // Added the index to the collection, and now the issue is resolved
  if (superUserAccess) {
    res = await members.find(
      {
        owner_id: {
          $in: userCustomData.workspaces.map((x) => x.group_id),
        },
      },
      {
        projection: {
          _id: { $toString: '$_id' },
          name: 1,
          phone: 1,
        },
        sort: { name: 1 },
      },
    );
  } else {
    res = await members.find(
      { owner_id: userCustomData.group_id },
      {
        projection: {
          _id: { $toString: '$_id' },
          name: 1,
          phone: 1,
        },
        sort: { name: 1 },
      },
    );
  }
  const membersListResponse = res.map((member) => {
    const text =
      member.name !== ''
        ? member.name + ', ' + toDomesticPhoneFormat(member.phone)
        : '';
    return {
      key: member._id,
      value: member._id,
      _id: member._id,
      name: member.name,
      phone: member.phone,
      email: member.email,
      text: text,
    };
  });
  membersListResponse.push({
    key: '',
    value: '',
    _id: '',
    name: '',
    phone: '',
    text: 'no owner',
  });
  if (isMounted) {
    setMemberList(membersListResponse);
  }
};

export const getDevices = async (
  superUserAccess,
  userCustomData,
  registry,
  deviceMap,
  isMounted,
  setOwnerMap,
  setDevices,
  setStats,
) => {
  let devicesRegistry = [];
  let devicesOwnerMap = {};
  let stats = {};
  if (superUserAccess) {
    devicesRegistry = await registry.find(
      { device: { $in: ['BT004', 'BT105', 'SMIG'] } },
      { sort: { lastUsed: -1 } },
    );
  } else {
    devicesRegistry = await registry.find(
      {
        owner_id: userCustomData.group_id,
        device: { $in: ['BT004', 'BT105', 'SMIG'] },
      },
      { sort: { lastUsed: -1 } },
    );
  }
  devicesRegistry.forEach((device) => {
    const text =
      device.name !== ''
        ? device.name + ', ' + toDomesticPhoneFormat(device.phone)
        : '';
    device.deviceType = deviceMap[device.device] || device.device;
    devicesOwnerMap[device._id] = {
      imei: device.imei,
      name: device.name,
      phone: device.phone,
      email: device.email,
      text: text,
      memberId:
        device.memberId === ''
          ? ObjectID(null).toHexString()
          : device.memberId,
      clicked: false,
      changed: false,
    };
    stats[device.deviceType] = stats[device.deviceType]
      ? stats[device.deviceType] + 1
      : 1;
  });
  if (isMounted) {
    setOwnerMap(devicesOwnerMap);
    setDevices(devicesRegistry);
    setStats(Object.entries(stats));
  }
};
