import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Message,
} from 'semantic-ui-react';
import { useStitchAuth } from './StitchAuth';

const PasswordResetForm = (props) => {
  const [email, setEmail] = useState('');

  const [formSuccess, setFormSuccess] = useState(false);

  const { actions } = useStitchAuth();

  const handleSubmit = (event) => {
    actions
      .handleResetPasswordSend(email)
      .then(() => {
        setFormSuccess(true);
      })
      .catch(() => {
        console.log('something went wrong...');
      });
  };

  return (
    <div
      style={{
        backgroundImage: 'url("/images/shutterstock_461838712.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Grid
        textAlign="center"
        style={{
          height: '100vh',
          margin: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{
            maxWidth: 450,
            backgroundColor: '#fff',
            padding: '3em',
          }}
        >
          <Header as="h2" textAlign="center">
            <Header.Content>Reset Password</Header.Content>
          </Header>
          <Form size="large" success={formSuccess}>
            <Segment style={{ border: '0', boxShadow: '0 0 0 0' }}>
              <Message
                success
                header="Successfully sent a Reset-Password Email"
                content="Check your emails and follow the link provided"
              />
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                value={email}
                onChange={(event) =>
                  setEmail(
                    event.target.value
                      .toLowerCase()
                      .replace(/\s/g, ''),
                  )
                }
              />
              <Button
                color="green"
                fluid
                size="large"
                onClick={handleSubmit}
              >
                Send a Reset-Password Email
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default PasswordResetForm;
