import React from 'react';
import { Icon } from 'semantic-ui-react';
import { title } from '../../../smsManualTemplates.json';

const TitleLabel = ({
  templateToSend,
  templateCategory,
  handleSubmit,
  templateName,
}) => (
  <div
    style={{
      justifyContent: 'center',
      paddingTop: '10px',
      alignItems: 'center',
      height: '35px',
      textAlign: 'center',
      fontSize: '14px',
      color: 'white',
      backgroundColor:
        templateToSend === title[templateCategory][templateName]
          ? 'orange'
          : '#384c74',
    }}
    onClick={() => {
      if (templateToSend === title[templateCategory][templateName])
        handleSubmit(templateName);
    }}
  >
    {templateToSend === title[templateCategory][templateName] ? (
      <>
        Send Message <Icon name="send" />
      </>
    ) : (
      title[templateCategory][templateName]
    )}
  </div>
);

export default TitleLabel;
