import React, { useEffect, useState } from 'react';
import {
  Grid,
  Header,
  List,
  Dimmer,
  Loader,
  Button,
  Dropdown,
} from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import { useStitchMembers } from '../StitchMembers';
import AlertBox from './Measurements/AlertBox';
import Moment from 'react-moment';
import 'moment-timezone';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// the value represents the db kardiaResponses we get from kardia AP, while text is user facing equivalent
const dxOptions = [
  {
    key: 'sinus_rhythm,multiple_pacs',
    text: 'Sinus Rhythm with SVE',
    value: 'sinus_rhythm,multiple_pacs',
  },
  {
    key: 'sinus_rhythm',
    text: 'Normal Sinus Rhythm',
    value: 'sinus_rhythm',
  },
  {
    key: 'sinus_rhythm,multiple_pvcs',
    text: 'Sinus Rhythm with PVCs',
    value: 'sinus_rhythm,multiple_pvcs',
  },
  { key: 'bradycardia', text: 'Bradycardia', value: 'bradycardia' },
  { key: 'tachycardia', text: 'Tachycardia', value: 'tachycardia' },
  {
    key: 'unclassified',
    text: 'Unclassified',
    value: 'unclassified',
  },
  { key: 'afib', text: 'Atrial Fibrillation', value: 'afib' },
  {
    key: 'sinus_rhythm,wide_qrs',
    text: 'Sinus Rhythm with Wide QRS',
    value: 'sinus_rhythm,wide_qrs',
  },
  { key: 'unreadable', text: 'Unreadable', value: 'unreadable' },
  { key: 'too_short', text: 'Too Short', value: 'too_short' },
  { key: 'no_analysis', text: 'No Analysis', value: 'no_analysis' },
];

// helper function to turn db values into readable diagnoses
export const makeReadable = (diagnosis) => {
  let readable = diagnosis;
  dxOptions.forEach((item) => {
    if (item['value'] === diagnosis) {
      readable = item['text'];
    }
  });
  return readable;
};

export default function ECGReports(props) {
  const { userCustomData } = useStitchAuth();
  const { memberOne, actions } = useStitchMembers();
  const [reports, setReports] = useState([]);
  const [pdf, setPdf] = useState();
  const [reportSelected, setReportSelected] = useState({
    recordingId: 'No Report',
  });
  const [numReports, setNumReports] = useState(10);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    actions.loadMember(props.memberId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.memberId]);

  useEffect(() => {
    if (memberOne) {
      let data = memberOne.ecgData || [];
      setReports(data.concat().reverse());

      data.forEach((d) => {
        if (
          reportSelected.recordingId === 'No Report' ||
          reportSelected.recordingId === d.recordingId
        ) {
          setReportSelected(d);
        }
      });
      setLoading(false);
    }
  }, [memberOne, reportSelected]);

  useEffect(() => {
    if (reportSelected.recordingId !== 'No Report') {
      actions
        .getEcgPdf(reportSelected.recordingId)
        .then((x) => setPdf(x.pdfBase64));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSelected]);

  const handleOverwrite = async (val) => {
    setLoading(true);
    if (reportSelected.recordingId !== 'No Report') {
      reports.forEach((x) => {
        if (x.recordingId === reportSelected.recordingId) {
          x.value = val;
          x.source = 'Manual Override';
        }
      });
      await actions.updateECGData(memberOne, [...reports].reverse());
      await actions.updateNote(
        memberOne,
        '<p>Manually overriding the Kardia Instant Analysis value (<i>' +
          reportSelected.kardiaRes +
          '</i>) to a new value (<b><i>' +
          val +
          '</i></b>) for ECG Report "' +
          reportSelected.recordingId +
          '" taken at ' +
          reportSelected.time +
          '.</p>',
        new Date().toISOString(),
        false,
      );
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <div className="messages box">
        <Grid style={{ margin: 0 }}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AlertBox showEcgAlerts={true} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="two">
            <Grid.Column width={4}>
              <Header as="h4">
                ECG Reports
                <Header.Subheader>
                  Click to see the report on the right
                </Header.Subheader>
              </Header>
              <List relaxed>
                {reports.slice(0, numReports).map((d) => (
                  <List.Item
                    key={d.recordingId}
                    style={{ fontWeight: 250 }}
                  >
                    <List.Description style={{ fontWeight: 300 }}>
                      <Button
                        basic
                        active={
                          d.recordingId === reportSelected.recordingId
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setReportSelected(d);
                          actions
                            .getEcgPdf(d.recordingId)
                            .then((x) => setPdf(x.pdfBase64));
                        }}
                      >
                        <Moment
                          format="MM/DD/YYYY hh:mm A"
                          tz={userCustomData.timeZone}
                          date={d.time}
                        />
                        &nbsp;
                        {/* The kardia api returns sinus_rhythm as normal. For old reports the normal value is 'Normal'. */}
                        <small>
                          {d.value === 'sinus_rhythm' ||
                          d.value === 'Normal' ? (
                            <span className="green text">
                              {makeReadable(d.value)}
                            </span>
                          ) : (
                            <span className="red text">
                              {makeReadable(d.value)}
                            </span>
                          )}
                        </small>
                      </Button>
                    </List.Description>
                  </List.Item>
                ))}
              </List>
              {numReports < reports.length && (
                <Button
                  basic
                  style={{ fontWeight: 300 }}
                  onClick={(e) => {
                    setNumReports(numReports + 10);
                  }}
                >
                  (show more reports)
                </Button>
              )}
            </Grid.Column>
            <Grid.Column width={12}>
              <Header.Subheader>
                {/* The kardia api returns sinus_rhythm as normal. For old reports the normal value is 'Normal'. */}
                {reportSelected.value === 'sinus_rhythm' ||
                reportSelected.value === 'Normal' ? (
                  <span className="green text">
                    {makeReadable(reportSelected.value)}
                  </span>
                ) : (
                  <span className="red text">
                    {makeReadable(reportSelected.value)}
                  </span>
                )}{' '}
                ({reportSelected.source}),{' '}
                <i>{reportSelected.bpm} BPM</i> <br />
                <Moment
                  format="MM/DD/YYYY hh:mm A"
                  tz={userCustomData.timeZone}
                  date={reportSelected.time}
                />
                ,{reportSelected.settings}
                <br />
                {false && (
                  <a href={reportSelected.url}>PDF Download</a>
                )}
              </Header.Subheader>
              <span style={{ fontWeight: 300 }}>
                Overwrite the Kardia Instant Analysis output
                with:&nbsp;
              </span>
              <Dropdown
                placeholder={reportSelected.value}
                search
                inline
                value={reportSelected.value}
                size="mini"
                options={dxOptions}
                onChange={(e, { value }) => handleOverwrite(value)}
              />
              <br />
              <small>
                {' '}
                Page {pageNumber} of {numPages}{' '}
              </small>
              &nbsp;&nbsp;
              {numPages > 1 && (
                <span>
                  <Button
                    size="mini"
                    basic
                    onClick={() =>
                      setPageNumber(Math.max(pageNumber - 1, 1))
                    }
                  >
                    Back
                  </Button>
                  <Button
                    size="mini"
                    basic
                    onClick={() =>
                      setPageNumber(
                        Math.min(pageNumber + 1, numPages),
                      )
                    }
                  >
                    Next
                  </Button>
                </span>
              )}
              {pdf && (
                <Document
                  file={'data:application/pdf;base64,' + pdf}
                  onLoadSuccess={({ numPages }) =>
                    setNumPages(numPages)
                  }
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
