import React from 'react';
import { Message } from 'semantic-ui-react';

export default function ContractWarning(props) {
  if (props.signedContract) {
    return <div></div>;
  } else {
    return (
      <Message negative>
        <Message.Header>
          You are on a trial version of Orma RPM.
        </Message.Header>
        Please use our trial version at your own risk. We are not
        responsible for any data loss, data breach, device maintenance
        for the trial version.
        <br />
        To switch to our Standard version, please contact
        sales@ormahealth.com to sign our customer agreement.
      </Message>
    );
  }
}
