import React, { useState, useEffect, Suspense } from 'react';
import {
  Menu,
  Header,
  Breadcrumb,
  Divider,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import {
  StitchMembersProvider,
  useStitchMembers,
} from '../StitchMembers';
import AppBar from '../Common/AppBar/AppBar';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
  Link,
  Switch,
  Route,
} from 'react-router-dom';
import InputSection from './InputSection';
import HeaderSection from './HeaderSection';
import CallModal from '../TwilioPhoneCalls/CallModal';
import { numUnreadMessages } from '../Common/memberUtils';

function NumTextMsg(props) {
  const { memberOne } = useStitchMembers();
  if (!memberOne || numUnreadMessages(memberOne.messageData) === 0) {
    return <span></span>;
  } else {
    return <span>({numUnreadMessages(memberOne.messageData)})</span>;
  }
}

export default function ProspectDetails() {
  const { userCustomData } = useStitchAuth();
  const { prospectId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const viewList = [
    {
      pathname: 'text-messages',
      title: 'Text Messages',
      component: React.lazy(() => import('./Messages')),
      divider: false,
    },
    {
      pathname: 'phone-calls',
      title: 'Phone Calls',
      component: React.lazy(() =>
        import('../TwilioPhoneCalls/PhoneLog'),
      ),
      divider: false,
    },
    {
      pathname: 'notes',
      title: 'Notes',
      component: React.lazy(() => import('./Notes')),
      divider: false,
    },
    {
      pathname: 'edit-profile',
      title: 'Edit Profile',
      component: React.lazy(() => import('./EditProfile')),
      divider: false,
    },
  ];

  useEffect(() => {
    if (userCustomData && loading) {
      setLoading(false);
    }
  }, [userCustomData, loading]);

  if (loading) {
    return (
      <div className="app-layout">
        <AppBar />
        <Workspace />
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    return (
      <StitchMembersProvider>
        <CallModal />
        {/* The call modal lives here to it persists as menus are changed. */}
        <div className="app-layout">
          <AppBar />
          <Workspace />
          <div className="channels box">
            <Breadcrumb
              style={{ paddingLeft: '1em', fontSize: '0.8em' }}
            >
              <Breadcrumb.Section
                link
                style={{ color: '#fff' }}
                onClick={() =>
                  history.push({
                    pathname: '/prospects',
                  })
                }
              >
                prospects
              </Breadcrumb.Section>
              <Breadcrumb.Divider
                icon="right angle"
                style={{ color: '#fff' }}
              />
              <Breadcrumb.Section style={{ color: '#fff' }}>
                prospect-details
              </Breadcrumb.Section>
            </Breadcrumb>
            <Header
              as="h4"
              style={{ paddingLeft: '1em', color: '#fff' }}
            >
              Prospect Details
            </Header>
            <Menu
              vertical
              secondary
              inverted
              style={{ background: 'transparent' }}
            >
              {viewList.map((view) => (
                <span key={view.pathname}>
                  <Menu.Item
                    active={
                      location.pathname ===
                      `/prospect-details/${prospectId}/${view.pathname}`
                    }
                  >
                    <Link to={`${url}/${view.pathname}?`}>
                      {`#  ${view.pathname}`}
                    </Link>
                    {view.pathname === 'text-messages' && (
                      <NumTextMsg />
                    )}
                  </Menu.Item>
                  {view.divider && <Divider />}
                </span>
              ))}
            </Menu>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {viewList.map((view) => (
                <Route
                  key={view.pathname}
                  path={`${path}/${view.pathname}`}
                >
                  <HeaderSection
                    prospectId={prospectId}
                    title={view.title}
                  />
                  <view.component prospectId={prospectId} />
                  <InputSection pathname={view.pathname} />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </div>
      </StitchMembersProvider>
    );
  }
}
