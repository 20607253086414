import { demoWorkspace } from './Values';
import { getYMD } from './analytics';

export const dobStr = (dob) => {
  return (
    dob.substring(5, 7) +
    '/' +
    dob.substring(8, 10) +
    '/' +
    dob.substring(0, 4)
  );
};

export const age = (dob) => {
  const tNow = new Date().getTime();
  return Math.floor((tNow - new Date(dob).getTime()) / 31536000000);
};

export const recentWarnings = (warningLog) => {
  if (warningLog && warningLog.length > 0) {
    return warningLog.reverse().slice(0, 3);
  } else {
    return [];
  }
};

export const activeMeds = (medications) => {
  const ymRef = getYMD(); // current ymRef
  return (medications || []).filter(
    (med) => med.endYM === '' || med.endYM >= ymRef,
  );
};

export const latestMessageDT = (messageData) => {
  messageData = messageData || [];
  if (messageData.length > 0) {
    const lastIdx = messageData.length - 1;
    return messageData[lastIdx].time;
  }
  return '1970-01-01';
};

export const latestMessage = (messageData) => {
  messageData = messageData || [];
  if (messageData.length > 0) {
    const lastIdx = messageData.length - 1;
    return messageData[lastIdx].value;
  }
  return '';
};

export const messageFreq = (messageData) => {
  const tNow = new Date().getTime();
  const d7iso = new Date(tNow - 7 * 86400000).getTime();
  return (messageData || []).filter(
    (message) => new Date(message.time).getTime() > d7iso,
  ).length;
};

export const activeMemos = (noteData) => {
  return (noteData || []).filter(
    (note) =>
      note.noteType &&
      note.noteType === 'memo' &&
      note.isActive &&
      !note.isDeleted,
  );
};

export const numUnreadMessages = (messageData) => {
  return (messageData || []).filter(
    (message) => !message.isRead && !message.msgType,
  ).length;
};

export const isDemoAccountMember = (member) => {
  return member && member.owner_id == demoWorkspace;
}
