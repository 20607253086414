import React from 'react';
import { Grid } from 'semantic-ui-react';

const QuickClinicalGuide = () => {
  return (
    <Grid.Column width={6}>
      <small className="grey text">
        <p>
          NOTE 1: Quick Clinical Guide for RPM Deployment
          <br />- <b>CHF/HF</b>: Weight Scale, BP Cuff <br />-{' '}
          <b>Diabetes</b>: Glucometer <br />- <b>HTN</b>: BP Cuff{' '}
          <br />- <b>COPD</b>: BP Cuff <br />- <b>Obesity</b>: Weight
          Scale <br />- <b>CKD stage 3-5</b>: BP Cuff, Glucometer,
          Weight Scale <br />- <b>ESRD</b>: BP Cuff, Glucometer,
          Weight Scale <br />- <b>CAD/ASHD</b>: BP Cuff, ECG <br />
        </p>
        <p>
          NOTE 2: If you have already identified a list of members,
          please contact us for a bulk upload of the member
          information.
        </p>
        <p>
          NOTE 3: For the RPM devices, click "Request" if you want the
          device to be sent to the patient's home. If the patient
          already has the device, click "Connect". Need the device's
          IMEI (15 digit number for Weight Scale and BP Cuff and 7
          digit number for Glucometer).
        </p>
        <p>
          RESOURCE 1: If you want a written patient form for RPM, you
          can download{' '}
          <a href="/data/RPM-consent.pdf" download>
            this generic template
          </a>{' '}
          and customize it for your practice.
        </p>
      </small>
    </Grid.Column>
  );
};

export default QuickClinicalGuide;
