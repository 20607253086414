import React from 'react';
import { DateTimeInput } from 'semantic-ui-calendar-react';

const DateTimePicker = ({ setDate }) => {
  const handleDateChange = (e, { name, value }) => {
    setDate(value);
  };

  return (
    <DateTimeInput
      name="dateTime"
      placeholder="Date Time"
      iconPosition="left"
      timeFormat="AMPM"
      dateFormat="MMM DD YYYY"
      onChange={handleDateChange}
      value={new Date().toDateString()}
    />
  );
};

export default DateTimePicker;
