import React, { useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  Icon,
  Input,
  Radio,
  Modal,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { formatDate } from '../../Common/utils';
import moment from 'moment';
import { frequencyEnums, generateRepitions } from './CareCalendar';

const repeatOptions = [];
for (let i = 2; i <= 100; i++) {
  repeatOptions.push({ text: i, value: i, id: i });
}

const SmallGap = styled.div`
  margin-bottom: 12px;
`;

const Bold = styled.div`
  font-weight: 600;
`;

const EndsDeadSpace = styled.div`
  height: 51px;
`;

const Italic = styled.span`
  font-style: italic;
  font-weight: 600;
`;

const SummaryGap = styled.div`
  height: 20px;
`;

export default function EventCreationModal({
  dbWrite,
  eventToEdit,
  open,
  onClose,
  setLoading,
}) {
  // values for what goes in the form inputs
  const [eventName, setEventName] = useState('');
  const [startingDate, setStartingDate] = useState('');
  const [frequency, setFrequency] = useState('');
  const [multiOccurences, setMultiOccurences] = useState(2);
  const [indefinitelySelected, setIndefinitelySelected] =
    useState(true);

  // fill form inputs with event to edit fields
  useEffect(() => {
    if (eventToEdit) {
      setEventName(eventToEdit.name);
      setStartingDate(
        moment(eventToEdit.start).utc().format('MM/DD/YYYY'),
      );
      setFrequency(eventToEdit.frequency);
      setMultiOccurences(Math.max(2, eventToEdit?.repitions || 2));
      setIndefinitelySelected(eventToEdit.repitions < 0);
    }
  }, [eventToEdit]);

  const clearValues = () => {
    setEventName('');
    setStartingDate('');
    setFrequency('');
    setMultiOccurences(2);
    setIndefinitelySelected(true);
  };

  // draft event built from form inputs, used to generate repitions and is what is saved to db.
  let draftEvent = null;
  if (startingDate.length === 10 && frequency) {
    let repitions = multiOccurences;
    if (frequency === frequencyEnums.SINGLE_EVENT) {
      repitions = 1;
    } else if (indefinitelySelected) {
      repitions = -1;
    }

    draftEvent = {
      name: eventName,
      start:
        new Date(startingDate).toISOString().substring(0, 11) +
        '00:00:00.000Z',
      frequency: frequency,
      repitions: repitions,
    };
  }

  let endDate = '';
  let summary = '';
  if (
    startingDate.length === 10 &&
    frequency &&
    frequency !== frequencyEnums.SINGLE_EVENT
  ) {
    const singleEvents = generateRepitions(draftEvent);
    if (!indefinitelySelected) {
      endDate = moment(singleEvents[singleEvents.length - 1]).format(
        'dddd, MMMM Do, YYYY',
      );
    }
    // generating summary
    summary = `${moment(singleEvents[0]).format('M/D')}`;
    if (indefinitelySelected || singleEvents.length <= 5) {
      for (let i = 1; i < Math.min(singleEvents.length, 5); i++) {
        summary += `, ${moment(singleEvents[i]).format('M/D')}`;
      }
    } else {
      summary += `, ${moment(singleEvents[1]).format(
        'M/D',
      )}, ${moment(singleEvents[2]).format('M/D')} . . . ${moment(
        singleEvents[singleEvents.length - 1],
      ).format('M/D/YY')}`;
    }
    if (indefinitelySelected && singleEvents.length > 5) {
      summary += ' . . .';
    }
  }

  let textDate = '';
  let dayRep = '';
  let weekOfMonth = '';
  if (startingDate.length === 10) {
    textDate = moment(startingDate).format('dddd, MMMM Do, YYYY');
    dayRep = moment(startingDate).format('dddd');
    const weekOfMonthNum = Math.ceil(moment(startingDate).date() / 7);
    if (weekOfMonthNum === 1) {
      weekOfMonth = '1st';
    } else if (weekOfMonthNum === 2) {
      weekOfMonth = '2nd';
    } else if (weekOfMonthNum === 3) {
      weekOfMonth = '3rd';
    } else if (weekOfMonthNum === 4) {
      weekOfMonth = '4th';
    } else {
      weekOfMonth = 'last';
    }
  }

  return (
    <Modal onClose={onClose} open={open} size="tiny">
      <Modal.Header>
        {eventToEdit ? 'Edit Event' : 'Create Event'}
      </Modal.Header>
      <Modal.Content>
        <>
          <Bold>Event Name:</Bold>
          <Input
            style={{ width: '332px' }}
            placeholder="ex: Weekly Check-in"
            value={eventName}
            onChange={(event) => {
              setEventName(event.target.value);
            }}
          />
          <SmallGap />
          <Bold>
            {frequency === frequencyEnums.SINGLE_EVENT
              ? 'Event Date:'
              : 'Event Start:'}
          </Bold>
          <Input
            style={{ width: '122px', marginRight: '6px' }}
            placeholder="MM/DD/YYYY"
            value={startingDate}
            onChange={(event) => {
              setStartingDate(
                formatDate(startingDate, event.target.value),
              );
            }}
          />
          <Italic>{textDate}</Italic>
          <SmallGap />
          <Bold>Frequency:</Bold>
          <Radio
            label="Single Event"
            checked={frequency === frequencyEnums.SINGLE_EVENT}
            onChange={() => {
              setFrequency(frequencyEnums.SINGLE_EVENT);
            }}
          />
          <br />
          <Radio
            label={
              dayRep
                ? `Repeats Weekly on ${dayRep}s`
                : 'Repeats Weekly'
            }
            checked={frequency === frequencyEnums.WEEKLY}
            onChange={() => {
              setFrequency(frequencyEnums.WEEKLY);
            }}
          />
          <br />
          <Radio
            label={
              dayRep
                ? `Repeats Every Other ${dayRep}`
                : 'Repeats Every Other Week'
            }
            checked={frequency === frequencyEnums.EVERY_OTHER_WEEK}
            onChange={() => {
              setFrequency(frequencyEnums.EVERY_OTHER_WEEK);
            }}
          />
          <br />
          <Radio
            label={
              dayRep
                ? `Repeats Monthly on ${weekOfMonth} ${dayRep} of Month`
                : 'Repeats Monthly'
            }
            checked={frequency === frequencyEnums.MONTHLY}
            onChange={() => {
              setFrequency(frequencyEnums.MONTHLY);
            }}
          />
          {frequency && frequency !== frequencyEnums.SINGLE_EVENT ? (
            <>
              <SmallGap />
              <Bold>Ends:</Bold>
              <Radio
                label="Repeats Indefinitely"
                checked={indefinitelySelected}
                onChange={() => {
                  setIndefinitelySelected(true);
                }}
              />
              <br />
              <Radio
                label="Repeats"
                checked={!indefinitelySelected}
                onChange={() => {
                  setIndefinitelySelected(false);
                }}
              />{' '}
              <Dropdown
                inline
                disabled={indefinitelySelected}
                search
                value={multiOccurences}
                options={repeatOptions}
                onChange={(e, { value }) => setMultiOccurences(value)}
              />{' '}
              times {endDate && <Italic>(ends {endDate})</Italic>}
            </>
          ) : (
            <EndsDeadSpace />
          )}
          <br />
          <br />
          <SmallGap />
          {summary ? <Bold>{summary}</Bold> : <SummaryGap />}
        </>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            clearValues();
            onClose();
          }}
        >
          <Icon name="x" /> Cancel
        </Button>
        <Button
          disabled={!draftEvent || eventName.length === 0}
          color="green"
          onClick={async () => {
            setLoading(true);
            await dbWrite(draftEvent);
            clearValues();
            onClose();
            setLoading(false);
          }}
        >
          {eventToEdit ? (
            <>
              <Icon name="save" /> Save Changes
            </>
          ) : (
            <>
              <Icon name="calendar" /> Create Event
            </>
          )}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
