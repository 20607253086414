import React from 'react';
import { Menu, Header, Grid } from 'semantic-ui-react';
import { StitchMembersProvider } from '../StitchMembers';
import AppBar from '../Common/AppBar/AppBar';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import ContentTable from './ContentTable';

export default function ProspectList() {
  return (
    <div className="app-layout">
      <AppBar />
      <Workspace />
      <div className="channels box">
        <Header as="h4" style={{ paddingLeft: '1em', color: '#fff' }}>
          View Options
        </Header>
        <Menu
          vertical
          secondary
          inverted
          style={{ background: 'transparent' }}
        >
          <Menu.Item active={true} onClick={() => {}}>
            #&nbsp;&nbsp;all-prospects
          </Menu.Item>
        </Menu>
      </div>
      <div className="header-panel box">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h4">
                Prospective Members
                <Header.Subheader>
                  <small>
                    last updated at {new Date().toLocaleString()}
                  </small>
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div
        className="messages box"
        style={{ padding: '1em', paddingBottom: '5em' }}
      >
        <StitchMembersProvider>
          <ContentTable />
        </StitchMembersProvider>
      </div>
    </div>
  );
}
