import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Modal,
} from 'semantic-ui-react';
import { useStitchMembers } from '../../StitchMembers';
import { useStitchAuth } from '../../StitchAuth';

const Small = styled.div`
  font-size: 10px;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const nameString = (members) => {
  let result = '';
  members.forEach((member, i) => {
    result += member.name;
    if (i < members.length - 1) {
      result += ', ';
    }
  });
  return result;
};

export default function GroupRewardsModal({
  members,
  isOpen,
  onClose,
}) {
  const { currentUser } = useStitchAuth();
  const { actions } = useStitchMembers();
  const [loading, setLoading] = useState(false);
  const [rewardSentSuccess, setRewardSentSuccess] = useState(false);
  const [membersToSend, setMembersToSend] = useState([]);
  const [membersAlreadyGiven, setMembersAlreadyGiven] = useState([]);
  const [membersNoEmail, setMembersNoEmail] = useState([]);

  // fetch the reward amounts for each member and figure out which member to send, has already given, and has no email
  useEffect(() => {
    setLoading(true);
    if (isOpen) {
      const memberSetUp = async () => {
        const rewardAmounts = await Promise.all(
          members.map((member) =>
            actions.getRewardAmountThisMonth(member._id.toString()),
          ),
        );
        const membersToSendNew = [];
        const membersAlreadyGivenNew = [];
        const membersNoEmailNew = [];
        for (let i = 0; i < members.length; i++) {
          if (rewardAmounts[i] > 0) {
            membersAlreadyGivenNew.push(members[i]);
          } else if (!members[i].email) {
            membersNoEmailNew.push(members[i]);
          } else {
            membersToSendNew.push(members[i]);
          }
        }
        setMembersToSend(membersToSendNew);
        setMembersAlreadyGiven(membersAlreadyGivenNew);
        setMembersNoEmail(membersNoEmailNew);
      };
      memberSetUp().finally(() => {
        setLoading(false);
      });
    }
  }, [actions, members, isOpen]);

  const handleSubmit = async () => {
    setLoading(true);
    await Promise.all(
      membersToSend.map((member) =>
        actions.createTangoOrder(member._id.toString(), true, {
          orderedBy: currentUser.profile.email,
          time: new Date().toISOString(),
          numMembersAttempted: membersToSend.length,
        }),
      ),
    );
    setRewardSentSuccess(true);
    setLoading(false);
  };

  const fullClose = () => {
    setRewardSentSuccess(false);
    onClose();
  };

  return (
    <>
      <Modal onClose={fullClose} open={isOpen} size="fullscreen">
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        <Modal.Header>Send Group Reward</Modal.Header>
        <Modal.Content scrolling>
          {rewardSentSuccess ? (
            <>Rewards sent successfully!</>
          ) : (
            <>
              {membersToSend.length > 0 && (
                <>
                  <Bold>
                    Give $5 Reward to{' '}
                    {membersToSend.length === 1
                      ? 'this selected member'
                      : `each of these ${membersToSend.length}
                selected members`}
                    : (${5 * membersToSend.length} total in rewards)
                  </Bold>
                  <br />
                  <Small>{nameString(membersToSend)}</Small>
                  <br />
                </>
              )}
              {membersAlreadyGiven.length > 0 && (
                <>
                  <Bold>
                    {membersAlreadyGiven.length === 1
                      ? 'This selected member has'
                      : `These ${membersAlreadyGiven.length} selected members
                  have`}{' '}
                    received an award this month and cannot receive
                    another:
                  </Bold>
                  <br />
                  <Small>{nameString(membersAlreadyGiven)}</Small>
                  <br />
                </>
              )}
              {membersNoEmail.length > 0 && (
                <>
                  <Bold>
                    {membersNoEmail.length === 1
                      ? 'This selected member without an email address'
                      : `These ${membersNoEmail.length} selected members without email
                  addresses`}{' '}
                    cannot receive a reward:
                  </Bold>
                  <br />
                  <Small>{nameString(membersNoEmail)}</Small>
                </>
              )}
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          {rewardSentSuccess ? (
            <>
              <Button
                color="red"
                onClick={() => {
                  fullClose();
                }}
              >
                <Icon name="ban" /> Close
              </Button>
            </>
          ) : (
            <>
              <Button
                color="red"
                onClick={() => {
                  fullClose();
                }}
              >
                <Icon name="x" /> Cancel
              </Button>
              <Button
                disabled={membersToSend.length === 0}
                color="green"
                onClick={async () => {
                  handleSubmit();
                }}
              >
                <Icon name="dollar sign" /> Send Rewards ($
                {5 * membersToSend.length})
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}
