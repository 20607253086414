import React from 'react';
import { Form } from 'semantic-ui-react';

const AddressSection = ({ address, setAddress }) => {
  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddress({ ...address, [name]: value });
  };

  return (
    <>
      <Form.Group widths="equal">
        <Form.Input
          required
          name="line1"
          label="Address 1"
          placeholder="Street Address"
          value={address.line1}
          onChange={handleAddressChange}
        />
        <Form.Input
          name="line2"
          label="Address 2"
          placeholder="Apartment, Building, Floor, etc."
          value={address.line2}
          onChange={handleAddressChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          required
          name="city"
          label="City"
          placeholder="City"
          value={address.city}
          onChange={handleAddressChange}
        />
        <Form.Input
          required
          name="state"
          label="State"
          placeholder="State"
          value={address.state}
          onChange={handleAddressChange}
        />
        <Form.Input
          required
          name="zipCode"
          label="Zip/Postal Code"
          placeholder="00000"
          value={address.zipCode}
          onChange={handleAddressChange}
        />
      </Form.Group>
    </>
  );
};

export default AddressSection;
