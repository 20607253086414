import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Table,
  Loader,
  Dimmer,
  Grid,
  Pagination,
} from 'semantic-ui-react';
import ErrorBoundary from 'react-error-boundary';
import 'moment-timezone';
import { useStitchMembers } from '../../StitchMembers';
import { useStitchAuth } from '../../StitchAuth';
import { getYM } from '../../Common/analytics';
import { useGeneralParams } from '../../GeneralParams';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import GroupActions from './GroupActions';

export default function ContentTable({ listFilter }) {
  const { currentUser, userCustomData } = useStitchAuth();
  const { memberAll, nTot, nLimit, actions } = useStitchMembers();
  const { showParam, sortParam, setSortParam, pageNum, setPageNum } =
    useGeneralParams();
  const [members, setMembers] = useState([]);
  const [columns, setColumns] = useState({});
  const [loading, setLoading] = useState(false);
  const [membersSelected, setMembersSelected] = useState(new Set());

  useEffect(() => {
    let sortOption = { dataReceivedDT: -1 };
    if (sortParam === 'measureDateAsc') {
      sortOption = { dataReceivedDT: 1 };
    } else if (sortParam === 'activityDesc') {
      sortOption = { measureFreq: -1 };
    } else if (sortParam === 'activityAsc') {
      sortOption = { measureFreq: 1 };
    } else if (sortParam === 'nameDesc') {
      sortOption = { name: -1 };
    } else if (sortParam === 'nameAsc') {
      sortOption = { name: 1 };
    }

    if (userCustomData) {
      setLoading(true);
      setMembers([]);
      actions.reset();
      let query = { disenrolled: false };
      if (listFilter === 'disenrolled') {
        query.disenrolled = true;
      }
      if (showParam !== 'all') {
        query['$or'] = [
          { assignedPhysician: showParam },
          { assignedCareManager: showParam },
        ];
      }

      if (listFilter === 'new-members') {
        query.lastActiveDate = 0;
      } else if (listFilter === 'inactive-2-days') {
        query.lastActiveDate = {
          $lt: new Date().getTime() - 1000 * 60 * 60 * 24 * 2,
        };
      } else if (listFilter === 'inactive-5-days') {
        query.lastActiveDate = {
          $lt: new Date().getTime() - 1000 * 60 * 60 * 24 * 5,
        };
      } else if (listFilter === 'todo-99454') {
        query['billingInsights.CPT99454d'] = { $lt: 16 / 30 };
      } else if (listFilter === 'todo-99457-call') {
        query['billingInsights.CPT99457c'] = false;
      } else if (listFilter === 'todo-99457-review') {
        query['billingInsights.CPT99457a'] = false;
      } else if (listFilter === 'msg-unread') {
        query.numUnreadMessages = { $gt: 0 };
      } else if (listFilter === 'missed-call') {
        query.missedCall = { $exists: true };
      } else if (listFilter === 'needs-review') {
        //query.needsReview = true;
        query['warningLog.needsReview'] = true;
      } else if (listFilter.substring(0, 10) === 'Practice: ') {
        query.practiceId = listFilter.substring(10);
      } else if (listFilter === 'with-memo') {
        query['activeMemos.0'] = { $exists: true };
      } else if (listFilter === 'event-today') {
        query['days_till_event'] = 0;
      } else if (listFilter === 'sms-available') {
        query['optInSMS'] = true;
      } else if (listFilter === 'sms-not-available') {
        query['optInSMS'] = false;
      } else if (listFilter === 'ccm-members') {
        query['ccmPatientConsent'] = true;
      }

      actions
        .loadMembers(
          getYM(),
          userCustomData.group_id,
          query,
          sortOption,
          pageNum,
        )
        .then(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCustomData, listFilter, sortParam, showParam, pageNum]);

  // if the filter or sortParam change, clear all the selected members.
  useEffect(() => {
    setMembersSelected(new Set());
  }, [listFilter, showParam]);

  useEffect(() => {
    if (memberAll && memberAll.length > 0) {
      setColumns({
        bodyWeight: memberAll.some((x) => x.bodyWeight),
        systolicBP: memberAll.some((x) => x.systolicBP),
        pulse: memberAll.some((x) => x.pulse),
        ecg: memberAll.some((x) => x.ecg),
        bloodSugar: memberAll.some((x) => x.bloodSugar),
        step: memberAll.some((x) => x.step),
        spo2: memberAll.some((x) => x.spo2),
        pef: memberAll.some((x) => x.pef),
      });
      setMembers(memberAll);
    } else {
      setMembers([]);
      setColumns({
        bodyWeight: false,
        systolicBP: false,
        pulse: false,
        ecg: false,
        bloodSugar: false,
        step: false,
        spo2: false,
        pef: false,
      });
    }
  }, [
    memberAll,
    listFilter,
    showParam,
    sortParam,
    userCustomData.practiceList,
    currentUser.id,
  ]);

  if (loading) {
    return (
      <div className="messages box">
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    );
  } else {
    const allMembersSelected = _.isEqual(
      membersSelected,
      new Set(members.map((m) => m._id.toString())),
    );
    return (
      <ErrorBoundary>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              {!loading && (
                <GroupActions
                  membersFiltered={members}
                  membersSelected={membersSelected}
                  totalEnrolledCount={nTot}
                  totalMembersCount={nTot}
                  memberStartIdx={Math.min(
                    pageNum * nLimit + 1,
                    nTot,
                  )}
                  memberEndIdx={Math.min(
                    nTot,
                    nLimit * (pageNum + 1),
                  )}
                />
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Pagination
                size="mini"
                boundaryRange={0}
                defaultActivePage={pageNum + 1}
                siblingRange={1}
                totalPages={Math.ceil(nTot / nLimit)}
                onPageChange={(event, data) => {
                  setPageNum(data.activePage - 1);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table size="small" selectable basic="very">
          <TableHeader
            sortParam={sortParam}
            setSortParam={setSortParam}
            columns={columns}
            isCheckboxChecked={allMembersSelected}
            checkCheckbox={() => {
              if (allMembersSelected) {
                setMembersSelected(new Set());
              } else {
                // setting membersSelected to set of all memberId strings.
                setMembersSelected(
                  new Set(members.map((m) => m._id.toString())),
                );
              }
            }}
          />
          <Table.Body>
            {members.map((member) => (
              <TableRow
                key={member._id}
                member={member}
                columns={columns}
                userTimeZone={userCustomData.timeZone}
                isMemberSelected={membersSelected.has(
                  member._id.toString(),
                )}
                toggleMemberSelected={() => {
                  // function to select/deselect this particular member
                  const newMembersSelected = new Set(membersSelected);
                  if (newMembersSelected.has(member._id.toString())) {
                    newMembersSelected.delete(member._id.toString());
                  } else {
                    newMembersSelected.add(member._id.toString());
                  }
                  setMembersSelected(newMembersSelected);
                }}
              />
            ))}
          </Table.Body>
        </Table>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Pagination
                size="mini"
                boundaryRange={0}
                defaultActivePage={pageNum + 1}
                siblingRange={1}
                totalPages={Math.ceil(nTot / nLimit)}
                onPageChange={(event, data) => {
                  setPageNum(data.activePage - 1);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ErrorBoundary>
    );
  }
}
