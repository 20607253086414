import React, { useState } from 'react';
import { Tab, Card } from 'semantic-ui-react';
import { title, message } from '../../../smsManualTemplates.json';
import { useStitchMembers } from '../../../StitchMembers';
import { useStitchAuth } from '../../../StitchAuth';
import DateTimePicker from './DateTimePicker';
import '../../../Common/Layout.css';
import TitleLabel from './TitleLabel';

const cardGroup = {
  maxHeight: '200px',
  margin: '0',
  overflowY: 'auto',
};

const TemplateCategories = ({
  setTemplateLoading,
  measurementType,
  templateCategory,
  toggleTemplate,
}) => {
  const { memberOne, actions } = useStitchMembers();
  const { userCustomData } = useStitchAuth();
  const [templateToSend, setTemplateToSend] = useState(null);
  const [content, setContent] = useState(null);
  const lastReading = {
    measureType: '',
    values: null,
    unit: '',
  };
  const [date, setDate] = useState('$DATETIME');
  let titleName = '';
  if (memberOne.memberTitle) {
    titleName = memberOne.memberTitle;
  } else {
    titleName = memberOne.gender === 'male' ? 'Mr.' : 'Ms.';
  }
  const firstName = titleName + ' ' + memberOne.name.split(' ')[0];
  const titles = Object.keys(title[templateCategory]);

  if (memberOne) {
    if (memberOne.bodyWeightData.length > 0) {
      lastReading.measureType = 'weight measure';
      lastReading.unit = 'lbs';
      const lastValue =
        memberOne.bodyWeightData[memberOne.bodyWeightData.length - 1]
          .value;
      lastReading.values = lastValue;
    } else if (memberOne.systolicBPData.length > 0) {
      lastReading.measureType = 'systolic blood pressure level';
      lastReading.unit = 'mmHg';
      const lastValue =
        memberOne.systolicBPData[memberOne.systolicBPData.length - 1]
          .value;
      lastReading.values = lastValue;
    } else if (memberOne.bloodSugarData.length > 0) {
      lastReading.measureType = 'blood sugar level';
      lastReading.unit = 'mg/dL';
      const lastValue =
        memberOne.bloodSugarData[memberOne.bloodSugarData.length - 1]
          .value;
      lastReading.values = lastValue;
    }
  }

  const handleContent = (templateName) =>
    message[templateCategory][templateName]
      .replace(/\$NAME/g, firstName)
      .replace(
        /\$USER/g,
        userCustomData.namePublic + ', ' + userCustomData.title,
      )
      .replace(/\$MEASUREMENT/g, measurementType)
      .replace(/\$PATIENTPORTAL/g, memberOne.patientPortalURL)
      .replace(
        /\$LASTREADING/g,
        `${Math.round(lastReading.values)} ${lastReading.unit}`,
      )
      .replace(/\$DATETIME/g, date);

  const handleSubmit = async (category) => {
    setTemplateLoading(true);
    await actions.updateMessage(memberOne, content, category);
    toggleTemplate();
    setTemplateToSend(null);
    setContent(null);
    setTemplateLoading(false);
  };

  const cardSelection = (templateName) => {
    if (!handleContent(templateName).includes('$MEASUREMENT')) {
      setTemplateToSend(title[templateCategory][templateName]);
      setContent(handleContent(templateName));
    }
  };

  return (
    <Tab.Pane style={{ maxHeight: '200px' }}>
      <Card.Group stackable itemsPerRow={3} style={cardGroup}>
        {titles.map((templateName) => (
          <Card
            onClick={() => cardSelection(templateName)}
            raised
            fluid
            key={templateName}
            style={{
              borderRadius: '7px',
              cursor: 'pointer',
              color: 'black',
              border:
                templateToSend ===
                title[templateCategory][templateName]
                  ? '2px solid #feb236'
                  : '',
            }}
            color={
              templateToSend === title[templateCategory][templateName]
                ? 'yellow'
                : 'black'
            }
          >
            <TitleLabel
              templateToSend={templateToSend}
              templateCategory={templateCategory}
              handleSubmit={handleSubmit}
              templateName={templateName}
            />
            <Card.Content
              style={{
                color: handleContent(templateName).includes(
                  '$MEASUREMENT',
                )
                  ? 'gray'
                  : 'black',
              }}
            >
              {message[templateCategory][templateName].includes(
                '$DATETIME',
              ) ? (
                <>
                  {handleContent(templateName)}
                  <DateTimePicker setDate={setDate} />
                </>
              ) : (
                handleContent(templateName)
              )}
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </Tab.Pane>
  );
};

export default TemplateCategories;
