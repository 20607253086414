import React from 'react';
import ReactQuill from 'react-quill';
import * as Emoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import NoteInput from './NoteInput';
import AlertInput from './AlertInput';
import MessageInput from './MessageInput';

const { Quill } = ReactQuill;
Quill.register('modules/emoji', Emoji);

export default function InputSection(props) {
  // NOTE: write box height, set as 300 px. quill
  if (props.pathname === 'text-messages') {
    return (
      <div className="write box">
        <MessageInput />
      </div>
    );
  } else if (props.pathname === 'alert-doctor') {
    return (
      <div className="write box">
        <AlertInput />
      </div>
    );
  } else {
    return (
      <div className="write box">
        <NoteInput />
      </div>
    );
  }
}
