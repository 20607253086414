import React from 'react';
import { Table, Button, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { changeOwnership } from '../DeviceAssignmentUtils';
import DevicesTableHeader from './DevicesTableHeader';

const DevicesListTable = ({
  devices,
  setDevices,
  sortParam,
  setSortParam,
  memberList,
  ownerMap,
  setOwnerMap,
  registry,
  setLoading,
}) => {
  return (
    <Table size="small" basic="very">
      <DevicesTableHeader
        sortParam={sortParam}
        setSortParam={setSortParam}
        devices={devices}
        setDevices={setDevices}
      />
      <Table.Body>
        {devices.map((device, index) => (
          <Table.Row key={index}>
            <Table.Cell>{device.imei}</Table.Cell>
            <Table.Cell>{device.deviceType}</Table.Cell>
            <Table.Cell>
              {device.device === 'SMIG'
                ? 'n/a'
                : device.signalStrength + ' %'}
            </Table.Cell>
            <Table.Cell>
              {device.device === 'SMIG'
                ? 'n/a'
                : device.batteryVoltage + ' mmV'}
            </Table.Cell>
            <Table.Cell>
              {device.lastUsed === ''
                ? 'n/a'
                : new Date(device.lastUsed).toLocaleString()}
            </Table.Cell>
            <Table.Cell
              className={
                (ownerMap[device._id] || {}).changed ? 'red text' : ''
              }
            >
              {ownerMap[device._id].clicked ? (
                <Dropdown
                  inline
                  scrolling
                  options={memberList}
                  value={ownerMap[device._id].memberId}
                  onChange={(e, { value }) => {
                    setOwnerMap({
                      ...ownerMap,
                      [device._id]: {
                        ...ownerMap[device._id],
                        memberId: value,
                        changed: true,
                      },
                    });
                  }}
                />
              ) : (
                <span>
                  <Link
                    to={`/member-details/${device.memberId}/profile`}
                  >
                    {ownerMap[device._id].text}
                  </Link>
                  <Button
                    floated="right"
                    size="mini"
                    color="violet"
                    disabled={device.imei === ''}
                    onClick={() =>
                      setOwnerMap({
                        ...ownerMap,
                        [device._id]: {
                          ...ownerMap[device._id],
                          clicked: true,
                        },
                      })
                    }
                  >
                    Change
                  </Button>
                </span>
              )}
              &nbsp;
              {(ownerMap[device._id] || {}).clicked && (
                <Button
                  floated="right"
                  size="mini"
                  color="green"
                  onClick={() =>
                    changeOwnership(
                      device._id,
                      memberList,
                      ownerMap,
                      setOwnerMap,
                      setLoading,
                      registry,
                    )
                  }
                >
                  Submit
                </Button>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default DevicesListTable;
