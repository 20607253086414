import React from 'react';
import { Form } from 'semantic-ui-react';

const NoteSection = ({ note, setNote }) => {
  const handleNoteChange = (event) => {
    const { value } = event.target;
    setNote(value);
  };

  return (
    <Form.TextArea
      name="note"
      label="Notes"
      placeholder="Additional information about the member."
      value={note}
      onChange={handleNoteChange}
    />
  );
};

export default NoteSection;
