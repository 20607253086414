import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Popup } from 'semantic-ui-react';
import 'moment-timezone';
import { useStitchAuth } from '../../StitchAuth';
import { saveSync } from 'save-file';
import GroupTextModal from './GroupTextModal';
import GroupRewardsModal from './GroupRewardsModal';

const Stats = styled.span`
  font-weight: 300;
  padding-right: 10px;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const exportMembers = async (members, otherUsers) => {
  const csvContents = members
    .map(
      (x) =>
        `${x.name}, ${x.gender}, ${x.dob.substring(0, 10)}, "${
          x.phone
        }",` +
        (otherUsers.find((y) => y.id === x.assignedPhysician) || {})
          .name +
        ', ' +
        (otherUsers.find((y) => y.id === x.assignedCareManager) || {})
          .name +
        `, ${x.practiceId || ''}`,
    )
    .join('\n');
  await saveSync(
    `Name,Gender,DoB,Phone,Physician,Care Manager,Practice\n${csvContents}`,
    'memberList.csv',
  );
};

export default function GroupActions({
  membersFiltered,
  membersSelected,
  totalMembersCount,
  memberStartIdx,
  memberEndIdx,
}) {
  const { otherUsers } = useStitchAuth();
  const [isOpenTextModal, setIsOpenTextModal] = useState(false);
  const [isOpenRewardsModal, setIsOpenRewardsModal] = useState(false);

  return (
    <>
      <Stats>
        <Bold>{membersSelected.size}</Bold> selected of{' '}
        <Bold>{membersFiltered.length}</Bold> ({totalMembersCount}{' '}
        total, showing {memberStartIdx} to {memberEndIdx})
      </Stats>
      <Button.Group basic size="small">
        <Popup
          hoverable
          trigger={
            <Button
              icon="download"
              disabled={membersSelected.size === 0}
              onClick={() => {
                exportMembers(
                  membersFiltered.filter((m) =>
                    membersSelected.has(m._id.toString()),
                  ),
                  otherUsers,
                );
              }}
            />
          }
          position="bottom center"
          content={'Download CSV'}
        />
        <Popup
          hoverable
          trigger={
            <Button
              disabled={membersSelected.size === 0}
              icon="edit"
              onClick={() => {
                setIsOpenTextModal(true);
              }}
            />
          }
          position="bottom center"
          content={'Send SMS Message'}
        />
        <Popup
          hoverable
          trigger={
            <Button
              disabled={membersSelected.size === 0}
              icon="dollar sign"
              onClick={() => {
                setIsOpenRewardsModal(true);
              }}
            />
          }
          position="bottom center"
          content={'Send Gift Card Reward'}
        />
      </Button.Group>
      <GroupTextModal
        members={membersFiltered.filter((m) =>
          membersSelected.has(m._id.toString()),
        )}
        isOpen={isOpenTextModal}
        onClose={() => {
          setIsOpenTextModal(false);
        }}
      />
      <GroupRewardsModal
        members={membersFiltered.filter((m) =>
          membersSelected.has(m._id.toString()),
        )}
        isOpen={isOpenRewardsModal}
        onClose={() => {
          setIsOpenRewardsModal(false);
        }}
      />
    </>
  );
}
