import React from 'react';
import { Modal } from 'semantic-ui-react';

const CongratsMessageModal = ({
  enrolled,
  refreshState,
  memberName,
}) => {
  return (
    <Modal open={enrolled} onClose={refreshState} basic closeIcon>
      <Modal.Header>Congrats on your New Enrollment!</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            {memberName.firstName + ' ' + memberName.lastName} is
            enrolled.
          </p>
          <p>
            A new member profile is created on the dashboard page. You
            will see new measurements on the dashboard as soon as the
            member starts to use the devices.
          </p>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default CongratsMessageModal;
