import React, { Suspense } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import '../Common/Layout.css';
import AppBar from '../Common/AppBar/AppBar';
import Workspace from '../Common/Workspace';
import '../Common/Layout.css';
import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  Link,
  useLocation,
} from 'react-router-dom';

export default function Settings() {
  const pvStart = new Date().getTime();
  //const [view, setView] = useState(viewDefault || 'user');
  let { path, url } = useRouteMatch();
  const viewList = [
    { pathname: 'user', title: 'User Account Settings' },
    {
      pathname: 'email-notifications',
      title: 'Email Notification Settings',
    },
    { pathname: 'clinical-rules', title: 'Clinical Rule Settings' },
    { pathname: 'sms-group', title: 'SMS Group-level Settings' },
    { pathname: 'sms-members', title: 'SMS Member-level Settings' },
    { pathname: 'sms-templates', title: 'SMS Template Settings' },
    {
      pathname: 'code-reporting',
      title: 'CPT Code Reporting Settings',
    },
    { pathname: 'manage-accounts', title: 'Other Accounts Settings' },
    { pathname: 'billing', title: 'Billing Settings' },
    { pathname: 'about-rpm', title: 'About RPM' },
    { pathname: 'about-ccm', title: 'About CCM' },
    { pathname: 'about-platform', title: 'About Platform' },
    { pathname: 'support-request', title: 'Support Request' },
  ];
  const location = useLocation();
  const FormUser = React.lazy(() => import('./FormUser'));
  const EmailNotifications = React.lazy(() =>
    import('./EmailNotifications'),
  );
  const Accounts = React.lazy(() => import('./Accounts/Accounts'));
  const ClinicalRules = React.lazy(() => import('./ClinicalRules'));
  const SMSGroup = React.lazy(() => import('./SMSGroup'));
  const SMSMembers = React.lazy(() => import('./SMSMembers'));
  const SMSTemplates = React.lazy(() => import('./SMSTemplates'));
  const Reporting = React.lazy(() => import('./Reporting/Reporting'));
  const Billing = React.lazy(() => import('./Billing'));
  const AboutRPM = React.lazy(() => import('./AboutRPM'));
  const AboutCCM = React.lazy(() => import('./AboutCCM'));
  const AboutPlatform = React.lazy(() => import('./AboutPlatform'));
  const SupportRequest = React.lazy(() => import('./SupportRequest'));

  return (
    <div className="app-layout">
      <AppBar />
      <Workspace />
      <div className="channels box">
        <Header as="h4" style={{ paddingLeft: '1em', color: '#fff' }}>
          Settings
        </Header>
        <Menu
          vertical
          secondary
          inverted
          style={{ background: 'transparent' }}
        >
          {viewList.map((view) => (
            <Menu.Item
              key={view.pathname}
              active={
                location.pathname === `/settings/${view.pathname}`
              }
            >
              <Link to={`${url}/${view.pathname}`}>
                {`#  ${view.pathname}`}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className="header-panel box">
        <Header as="h4">
          {
            (
              viewList.find(
                (view) =>
                  location.pathname === `/settings/${view.pathname}`,
              ) || {}
            ).title
          }
          <Header.Subheader>
            <small>
              last updated at {new Date(pvStart).toLocaleString()}
            </small>
          </Header.Subheader>
        </Header>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={path}>
            <Redirect to={{ pathname: `${path}/user` }} />
          </Route>
          <Route path={`${path}/user`}>
            <FormUser />
          </Route>
          <Route path={`${path}/email-notifications`}>
            <EmailNotifications />
          </Route>
          <Route path={`${path}/clinical-rules`}>
            <ClinicalRules />
          </Route>
          <Route path={`${path}/sms-group`}>
            <SMSGroup />
          </Route>
          <Route path={`${path}/sms-members`}>
            <SMSMembers />
          </Route>
          <Route path={`${path}/sms-templates`}>
            <SMSTemplates />
          </Route>
          <Route path={`${path}/code-reporting`}>
            <Reporting />
          </Route>
          <Route path={`${path}/manage-accounts`}>
            <Accounts />
          </Route>
          <Route path={`${path}/billing`}>
            <Billing />
          </Route>
          <Route path={`${path}/about-rpm`}>
            <AboutRPM />
          </Route>
          <Route path={`${path}/about-ccm`}>
            <AboutCCM />
          </Route>
          <Route path={`${path}/about-platform`}>
            <AboutPlatform />
          </Route>
          <Route path={`${path}/support-request`}>
            <SupportRequest />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
}
