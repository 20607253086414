import React from 'react';
import { Form } from 'semantic-ui-react';

const BloodPressureDevice = ({
  bt105,
  bt105Imei,
  setBt105,
  setBt105Imei,
  setSendEnrollmentText,
}) => {
  return (
    <>
      <Form.Group inline>
        <label>Blood Pressure Cuff</label>
        <Form.Radio
          label="Request"
          value="request"
          checked={bt105 === 'request'}
          onChange={() => setBt105('request')}
        />
        <Form.Radio
          label="Connect"
          value="connect"
          checked={bt105 === 'connect'}
          onChange={() => {
            setBt105('connect');
            setSendEnrollmentText(false);
          }}
        />
        <Form.Radio
          label="Not needed"
          value="notNeeded"
          checked={bt105 === 'notNeeded'}
          onChange={() => setBt105('notNeeded')}
        />
      </Form.Group>
      {bt105 === 'connect' && (
        <Form.Input
          fluid
          name="bt105Imei"
          label={
            'Blood Pressure Cuff (' +
            (15 - bt105Imei.length) +
            ' digit left)'
          }
          placeholder="8865284043011562"
          value={bt105Imei}
          onChange={(e) => {
            if (
              e.target.value.length < 16 &&
              !isNaN(e.target.value)
            ) {
              setBt105Imei(e.target.value);
            }
          }}
        />
      )}
    </>
  );
};

export default BloodPressureDevice;
