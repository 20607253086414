import moment from 'moment';
import { Link } from 'react-router-dom';
import { generateRepitions } from '../MemberDetails/CareCalendar/CareCalendar';
import { platformServices } from './Values';

const labelMap = {
  weightGainWarning: 'Weight Gain',
  weightLossWarning: 'Weight Loss',
  highBPWarning: 'High BP',
  lowBPWarning: 'Low BP',
  highHRWarning: 'High Pulse',
  lowHRWarning: 'Low Pulse',
  highBloodSugarWarning: 'High Sugar',
  lowBloodSugarWarning: 'Low Sugar',
  ecgWarning: 'ECG',
};

// These functions are responsible for generating the to-do items (used for to-do labels, to-do tooltip)
//  for a member, based off that member's billingInsights.

export const needsReviewMeasures = (
  member,
  separate,
  maxLength = null,
) => {
  const measuresToReview = (member.warningLog || [])
    .filter((warning) => warning.needsReview)
    .sort((a, b) => new Date(b.time) - new Date(a.time))
    .map((warning) => ({
      alert: labelMap[warning.category],
      date: moment(warning.time).tz(moment.tz.guess()).format('M/D'),
      exactTime: warning.time,
    }));

  if (measuresToReview.length === 0) {
    return [];
  }

  const pathMeasurements = `/member-details/${member._id}/measurements`;
  const pathEcg = `/member-details/${member._id}/ecg-reports`;

  if (separate) {
    const alertLabels = measuresToReview.map((warning) => ({
      listTitle: `${warning.alert} — ${moment(warning.exactTime)
        .tz(moment.tz.guess())
        .format('M/D/YYYY h:mm a')}`,
      title: `Review - ${warning.alert} (${warning.date})`,
      color: 'red',
      path: warning.alert === 'ECG' ? pathEcg : pathMeasurements,
      // will be used for clearing this specific alert
      exactTime: warning.exactTime,
      description: (
        <>
          This patient measurement triggered the practice's{' '}
          <Link target="_blank" to={'/settings/clinical-rules'}>
            clinical rules
          </Link>
          . Review and{' '}
          <Link
            to={warning.alert === 'ECG' ? pathEcg : pathMeasurements}
          >
            clear this alert
          </Link>
          .
        </>
      ),
    }));
    if (maxLength) {
      return alertLabels.slice(0, maxLength);
    } else {
      return alertLabels;
    }
  } else {
    let title = 'Review Measurements — ';
    measuresToReview.slice(0, maxLength).forEach((warning, i) => {
      if (i !== 0) {
        title += ', ';
      }
      title += `${warning.alert} on ${warning.date}`;
    });
    if (measuresToReview.length > maxLength) {
      title += '...';
    }
    const pl = measuresToReview.length > 1;
    // returning array to allow for full todo array being returned
    return [
      {
        title: title,
        color: 'red',
        description: (
          <>
            {pl
              ? 'These patient measurements'
              : 'This patient measurement'}{' '}
            triggered the practice's{' '}
            <Link target="_blank" to={'/settings/clinical-rules'}>
              clinical rules
            </Link>
            . Review and{' '}
            <Link to={pathMeasurements}>
              clear {pl ? 'these alerts' : 'this alert'}
            </Link>
            .
          </>
        ),
      },
    ];
  }
};

const missedCall = (member) => {
  if (member.missedCall) {
    const path = `/member-details/${member._id}/phone-calls`;

    return {
      title: `Missed Call (${moment(member.missedCall)
        .tz(moment.tz.guess())
        .format('M/D')})`,
      color: 'orange',
      path: path,
      description: (
        <>
          This member <Link to={path}>called in</Link> and is awaiting
          a call back.
        </>
      ),
    };
  }
  return null;
};

const unreadTextMessages = (member) => {
  if (member.numUnreadMessages > 0) {
    const path = `/member-details/${member._id}/text-messages`;

    return {
      title: `Unread Texts: ${member.numUnreadMessages}`,
      color: 'orange',
      path: path,
      description: (
        <>
          Read and respond to{' '}
          <Link to={path}>these text messages</Link>.
        </>
      ),
    };
  }
  return null;
};

const orderDevice = (member) => {
  if (member.devices?.length === 0 && !member.kardiaId) {
    const path = `/member-details/${member._id}/manage-devices`;

    return {
      title: 'Order Devices (99454)',
      color: 'blue',
      path: path,
      description: (
        <>
          <Link to={path}>Order devices</Link> for this member to
          start taking measurements.
        </>
      ),
    };
  }
  return null;
};

const remindFirstMeasure = (member, query) => {
  // Remind first measure only if device ordered done
  if (orderDevice(member, query)) {
    return null;
  }

  if (
    // if it's been 2 days since the device was received and still no measurement, show this todo.
    new Date().getTime() - member.deviceReceiptDate > 2 * 86400000 &&
    member.lastActiveDate <= 0
  ) {
    const path = `/member-details/${member._id}/profile`;

    return {
      title: 'Remind First Measure (99454)',
      color: 'blue',
      path: path,
      description: (
        <>
          <Link to={path}>Contact the member</Link> to help them take
          their first measurement.
        </>
      ),
    };
  }
  return null;
};

const remindDailyMeasure = (member, query) => {
  // remind daily measure only if device ordered and remind first measure all done.
  if (
    orderDevice(member, query) ||
    remindFirstMeasure(member, query)
  ) {
    return null;
  }

  if (member.billingInsights['99454'].CPT99454d < 16 / 30) {
    const path = `/member-details/${member._id}/billing-insights`;

    return {
      title: 'Remind Measure (99454)',
      color: 'blue',
      path: path,
      description: (
        <>
          This member has taken measurements on{' '}
          {member.billingInsights['99454'].CPT99454c}{' '}
          {member.billingInsights['99454'].CPT99454c === 1
            ? 'day'
            : 'days'}{' '}
          out of 16 needed by{' '}
          {moment(member.billingInsights['99454'].toDate99454)
            .tz(moment.tz.guess())
            .format('M/D/YY')}{' '}
          to bill 99454 (
          <Link to={path}>member's billing insights</Link>
          ). Remind them to take daily measurements.
        </>
      ),
    };
  }
  return null;
};

const checkinCall = (member) => {
  if (
    !member.billingInsights['99457'].CPT99457c &&
    !member.ccmBillingInsights['99490']?.CPT99490contactMade // in case this was false for ccm billing too.
  ) {
    const path = `/member-details/${member._id}/profile`;

    return {
      title: 'Check-in Call (99457)',
      color: 'blue',
      path: path,
      description: (
        <>
          <Link to={path}>Call this member</Link> before{' '}
          {moment(member.billingInsights['99457'].toDate99457)
            .tz(moment.tz.guess())
            .format('M/D/YY')}{' '}
          to bill 99457.
        </>
      ),
    };
  }
  return null;
};

const checkinCcmCall = (member) => {
  if (
    (member?.services || []).includes(platformServices.CCM) &&
    member?.careplans &&
    !member.ccmBillingInsights['99490']?.CPT99490contactMade
  ) {
    const path = `/member-details/${member._id}/profile`;

    return {
      title: 'CCM Check-in Call (99490)',
      color: 'blue',
      path: path,
      description: (
        <>
          <Link to={path}>Call this member</Link> before{' '}
          {moment(member.billingInsights['99457'].toDate99457)
            .tz(moment.tz.guess())
            .format('M/D/YY')}{' '}
          to bill 99490.
        </>
      ),
    };
  }
  return null;
};

const createCarePlan = (member) => {
  if (
    (member?.services || []).includes(platformServices.CCM) &&
    !member?.careplans
  ) {
    const path = `/member-details/${member._id}/care-plan`;

    return {
      title: 'Complete Care Plan',
      color: 'red',
      path: path,
      description: (
        <>
          Create Care Plan <Link to={path}>here</Link>.
        </>
      ),
    };
  }
  return null;
};

const reviewMember = (member, query) => {
  // if there are more than 15 days until the billing end date, don't show the review member todo
  // this will prevent overwhelming with too many todos at the beginning of the cycle
  if (
    member.billingInsights['99457'].toDate99457 -
      new Date().getTime() >
    15 * 86400000
  ) {
    return null;
  }

  if (!member.billingInsights['99457'].CPT99457a) {
    return {
      title: 'Review Member (99457)',
      color: 'blue',
      path: `/member-details/${member._id}/profile`,
      description: `Spend 20 minutes reviewing this member before ${moment(
        member.billingInsights['99457'].toDate99457,
      )
        .tz(moment.tz.guess())
        .format('M/D/YY')} to bill 99457.`,
    };
  }
  return null;
};

export const eventToday = (member, query) => {
  const labels = [];
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const todayTime = today.getTime();
  (member.events || []).forEach((event) => {
    generateRepitions(
      event,
      new Date(today.setMonth(new Date().getMonth() + 1)),
    ).forEach((rep) => {
      const repDate = new Date(rep).setHours(0, 0, 0, 0);
      if (repDate === todayTime) {
        labels.push({
          title: `${event.name}`,
          color: 'purple',
          path: `/member-details/${member._id}/profile`,
          description: `Today is the member's ${event.name}`,
        });
      }
    });
  });
  return labels;
};

export const daysTillEvent = (events) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const todayTime = today.getTime();
  let numDaysTillEvent = 1000;
  events.forEach((event) => {
    generateRepitions(
      event,
      new Date(today.setMonth(new Date().getMonth() + 1)),
    ).forEach((rep) => {
      const repDate = new Date(rep).setHours(0, 0, 0, 0);
      let numDaysTmp = Math.floor(
        (repDate - todayTime) / 1000 / 60 / 60 / 24,
      );
      if (numDaysTmp < numDaysTillEvent && numDaysTmp > -1) {
        numDaysTillEvent = numDaysTmp;
      }
    });
  });
  return numDaysTillEvent;
};

export const generateTodos = (member, forLabels, alertMaxLength) => {
  let allLabels =
    needsReviewMeasures(member, forLabels, alertMaxLength) || [];
  allLabels.push(createCarePlan(member)); // checks for CCM members with no care plans (G0506)
  allLabels.push(missedCall(member));
  allLabels.push(unreadTextMessages(member));
  allLabels.push(orderDevice(member));
  allLabels.push(remindFirstMeasure(member));
  allLabels.push(remindDailyMeasure(member));
  allLabels.push(checkinCall(member));
  allLabels.push(checkinCcmCall(member));
  allLabels.push(reviewMember(member));
  allLabels = allLabels.concat(eventToday(member));
  // filter out the nulls that these functions return if no labels
  return allLabels.filter((label) => !!label);
};
