import React, { useState, useEffect } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { Device } from '@twilio/voice-sdk';
import { useStitchMembers } from '../StitchMembers';
import { useGeneralParams } from '../GeneralParams';
const { Device } = require('twilio-client');

const Small = styled.span`
  font-size: 12px;
  font-style: italic;
`;

const StyledLink = styled.span`
  text-decoration: underline;
  color: black;
`;

export default function CallModal() {
  const {
    callModalOpen,
    isCallingMember,
    setCallModalOpen,
    setOngoingCall,
  } = useGeneralParams();
  const { memberOne, prospectOne } = useStitchMembers();
  // the call recipient can be a member or prospect, depending on how this component is called.
  const callee = isCallingMember ? memberOne : prospectOne;
  const [device, setDevice] = useState(null);
  const [status, setStatus] = useState('');

  useEffect(() => {
    fetch('https://api2.ormahealth.com/token').then((res) =>
      res.json().then((data) => {
        setDevice(
          new Device(data.token, {
            // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
            codecPreferences: ['opus', 'pcmu'],
            // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
            // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
            // a second time and sending the tone twice. This will be default in 2.0.
            fakeLocalDTMF: true,
            // Use `enableRingingState` to enable the device to emit the `ringing`
            // state. The TwiML backend also needs to have the attribute
            // `answerOnBridge` also set to true in the `Dial` verb. This option
            // changes the behavior of the SDK to consider a call `ringing` starting
            // from the connection to the TwiML backend to when the recipient of
            // the `Dial` verb answers.
            enableRingingState: true,
            debug: true,
          }),
        );
      }),
    );
  }, []);

  useEffect(() => {
    if (device) {
      device.on('ready', function (device) {
        setStatus('Ready for call.');
      });

      device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
        setStatus(
          'An Error occurred. Try refreshing or try again later.',
        );
      });

      device.on('connect', function (conn) {
        setStatus('Connected – On Call.');
      });

      device.on('disconnect', function (conn) {
        setStatus('Call completed.');
      });
    }
  }, [device]);

  const onCall = device && status === 'Connected – On Call.';

  useEffect(() => {
    setOngoingCall(onCall);
  }, [setOngoingCall, onCall]);

  return callee ? (
    <Modal
      size={'tiny'}
      open={callModalOpen}
      onClose={() => {
        setCallModalOpen(false);
      }}
    >
      <Modal.Header>Call Member {callee.name}</Modal.Header>
      <Modal.Content>
        {status}
        <br />
        <br />
        {status === 'Call completed.' ? (
          <>
            View call logs{' '}
            <Link
              onClick={() => {
                setStatus('Ready for call.');
                setCallModalOpen(false);
              }}
              to={`/${
                isCallingMember
                  ? 'member-details'
                  : 'prospect-details'
              }/${callee._id}/phone-calls`}
            >
              <StyledLink>here</StyledLink>
            </Link>
            . They may take a minute to update.
          </>
        ) : (
          <span>
            Make sure your volume and microphone are on.
            <br />
            <span className="red text">
              ALSO, PLEASE COMMUNICATE WITH THE PATIENT THAT THE CALL
              WILL BE TRANSCRIBED FOR CARE-MANAGEMENT PURPOSES.
            </span>
            <br />
            The trascripts will be accessible at{' '}
            <Link
              to={`/${
                isCallingMember
                  ? 'member-details'
                  : 'prospect-details'
              }/${callee._id}/phone-calls`}
            >
              <StyledLink># phone-calls</StyledLink>
            </Link>
          </span>
        )}
      </Modal.Content>
      <Modal.Actions>
        {device && !onCall && (
          <Button
            color="blue"
            onClick={() => {
              if (device) {
                device.connect({
                  To: callee.phone,
                });
              }
            }}
          >
            <Icon name="phone" /> Call <Small>({callee.phone})</Small>
          </Button>
        )}
        <Button
          color="red"
          onClick={() => {
            if (onCall) {
              device.disconnectAll();
            } else {
              setStatus('Ready for call.');
              setCallModalOpen(false);
            }
          }}
        >
          <Icon name="x" /> {onCall ? 'Hang Up' : 'Close'}
        </Button>
      </Modal.Actions>
    </Modal>
  ) : (
    <></>
  );
}
