import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useStitchMembers } from '../../StitchMembers';
import 'moment-timezone';

export default function ClearAlertButton({ alertTime }) {
  const { memberOne, actions } = useStitchMembers();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      loading={isLoading}
      color="red"
      compact
      size="small"
      onClick={async () => {
        setIsLoading(true);
        await actions.clearAlert(memberOne, alertTime);
        await actions.loadMember(memberOne._id);
        setIsLoading(false);
      }}
    >
      Clear Alert
    </Button>
  );
}
